import { createReducer } from '@reduxjs/toolkit';
import {
  ACCEPT_MEETING,
  CALL_STATUS,
  END_MEETING,
  INIT_MEETING,
  JOIN_MEETING,
  REJECT_MEETING,
} from '../constants/meeting';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = null;

export default createReducer(initState, {
  [INIT_MEETING.SUCCESS](state, action) {
    return action.payload;
  },
  [JOIN_MEETING.SUCCESS](state) {
    return state ? { ...state, status: CALL_STATUS.inCall } : initState;
  },
  [ACCEPT_MEETING.SUCCESS](state) {
    return state ? { ...state, status: CALL_STATUS.inCall } : initState;
  },
  [REJECT_MEETING.SUCCESS]() {
    return initState;
  },
  [END_MEETING.SUCCESS]() {
    return initState;
  },
});
