import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  WS_NEW_NOTIFY,
  WS_CLEAR_ACTOR_NOTIFY,
  WS_CREATE_ACCESS,
  MAKE_SOUND,
  OBJECT_TYPE,
} from 'constants';
import { WS_SIP_JOIN } from '@control-front-end/common/constants/meeting';
import { WS_CREATE_ACTOR } from '@control-front-end/common/constants/graphActors';
import { WS_CREATE_REACTION } from '@control-front-end/common/constants/reactions';
import AppUtils from '@control-front-end/utils/utils';
import getTranslation from '@control-front-end/utils/getTranslation';
import mes from '@control-front-end/app/src/components/Notifications/intl';
import { DateUtils } from 'mw-style-react';
import TabNotify from '@control-front-end/utils/tabNotify';
import * as NOTIFICATION from '@control-front-end/app/src/routes/Settings/components/Notifications/constants';

// Check whether the packet should be processed from WS
function* isHandleWSPacket(payload, userId) {
  const accounts = yield select((state) => state.accounts);
  const auth = yield select((state) => state.auth);
  const { accId } = payload.model;
  return accounts.active === accId && auth.id !== userId;
}

const NOTIFICATION_TYPE_BY_WS_NOTIF_TYPE = {
  [WS_CREATE_ACTOR]: NOTIFICATION.TYPE.NEW_ACTOR,
  [WS_CREATE_ACCESS]: NOTIFICATION.TYPE.NEW_ACTOR,
  [WS_CREATE_REACTION]: NOTIFICATION.TYPE.NEW_REACTION,
  [WS_SIP_JOIN]: NOTIFICATION.TYPE.NEW_ACTOR,
};

function* getNotificationAlerts({ wsNotifType, payload: { privs = {} } }) {
  const settings = yield select((state) => state.settings);

  const type = NOTIFICATION_TYPE_BY_WS_NOTIF_TYPE[wsNotifType];

  const importance =
    privs.sign || privs.execute
      ? NOTIFICATION.IMPORTANCE.IMPORTANT
      : NOTIFICATION.IMPORTANCE.DEFAULT;

  // All alerts enabled by default - if there are no custom settings for specific type -
  if (
    !settings.notificationsSettings ||
    (settings.notificationsSettings && !settings.notificationsSettings[type])
  ) {
    return NOTIFICATION.DEFAULT_ALERTS?.[type]?.[importance];
  }

  return settings.notificationsSettings[type][importance];
}

const NOTIF_ACTION_BY_OBJECT_TYPE = { [OBJECT_TYPE.account]: 'accounts' };

/**
 * Creates a notification and updates the notification list and alert actions if necessary.
 */
function* createNotification({ alert, model }) {
  const notifyList = yield select((state) => state.wsNotify);
  const copyNotifyList = notifyList.slice();
  const findNotify = copyNotifyList.find((i) => i.id === model.id);
  if (findNotify) return;
  copyNotifyList.unshift(model);
  yield put({ type: WS_NEW_NOTIFY, payload: copyNotifyList });
  if (alert) {
    TabNotify.updateTitle();
    TabNotify.setPopup(model);
  }
}

/**
 * Create an actor notification
 */
function* createActorNotification({
  payload,
  extraData,
  wsNotifType = 'WS_CREATE_ACTOR',
}) {
  const { packetId, model, userId, objType } = payload;

  if (!NOTIFICATION_TYPE_BY_WS_NOTIF_TYPE[wsNotifType]) return;

  const isHandle = yield call(isHandleWSPacket, payload, userId);
  if (!isHandle) return;

  const alerts = yield call(getNotificationAlerts, { wsNotifType, payload });

  if (alerts?.[NOTIFICATION.ALERT.SOUND]) {
    yield put({ type: MAKE_SOUND, payload: { type: 'notification' } });
  }

  const config = yield select((state) => state.config);
  const systemForms = yield select((state) => state.systemForms);
  const isEvent = systemForms.events.id === model.formId;
  const url =
    wsNotifType === WS_CREATE_REACTION
      ? `/actors_graph/${model.accId}/view/${model.treeInfo.rootActorId}?rid=${model.id}`
      : `/actors_graph/${AppUtils.makeShortAccountId(model.accId)}/${
          NOTIF_ACTION_BY_OBJECT_TYPE[objType] || 'view'
        }/${model.id}`;

  yield createNotification({
    model: {
      id: packetId || AppUtils.udid(),
      ownerId: model.userId,
      ownerAvatar: AppUtils.makeUserAvatar(model, config),
      ownerName: model.user.nick,
      description: AppUtils.removeHtmlTags(
        AppUtils.smileToHtml(model.description || '')
      ),
      attachments: model.attachments,
      title: objType === OBJECT_TYPE.account ? model.name : model.title,
      createdAt: DateUtils.unixtime(),
      actorId: model.id,
      accId: model.accId,
      rid: wsNotifType === WS_CREATE_REACTION ? model.id : null,
      type: isEvent ? 'newEvent' : 'newActor',
      appId: model.appId,
      url,
      ...extraData,
    },
    alert: alerts?.[NOTIFICATION.ALERT.PUSH],
  });
}

/**
 * Create a reaction notification
 */
function* createReactionNotification({ payload }) {
  const { model } = payload;
  const { id, accId, data, treeInfo } = model;
  const actorView = yield select((state) => state.actorView);
  if (data.type === 'view' || actorView.id === treeInfo.rootActorId) return;
  const url = `/actors_graph/${AppUtils.makeShortAccountId(accId)}/view/${
    treeInfo.rootActorId
  }?rid=${id}`;
  const extraData = {
    title: treeInfo.rootActorTitle,
    description: model.description,
    actorId: treeInfo.rootActorId,
    type: data.type,
    url,
  };
  yield createActorNotification({
    payload,
    extraData,
    wsNotifType: 'WS_CREATE_REACTION',
  });
}

/**
 * Create a notification when granting access to an actor
 */
function* createActorAccessNotification({ payload }) {
  const auth = yield select((state) => state.auth);
  const workspace = auth.workspaces[payload.model.accId] || {};
  const groups = workspace.groups.map((i) => i.id);
  const checkInvolvedUsers = [...groups, auth.id].some((i) =>
    payload.involvedUsers.includes(i)
  );
  if (!checkInvolvedUsers || !payload.notify) return;
  yield createActorNotification({ payload, wsNotifType: 'WS_CREATE_ACCESS' });
}

/**
 * Clear event notifications
 */
function* clearNotification({ payload: actorId }) {
  const notifyList = yield select((state) => state.wsNotify);
  const filterNotify = notifyList.filter((i) => i.actorId !== actorId);
  yield put({ type: WS_NEW_NOTIFY, payload: filterNotify });
}

/**
 * User started new meeting notification
 */
function* sipJoin({ payload }) {
  const { model, user, userId } = payload;
  const config = yield select((state) => state.config);
  const isHandle = yield call(isHandleWSPacket, payload, userId);
  if (!isHandle) return;
  const url = `/actors_graph/${AppUtils.makeShortAccountId(model.accId)}/view/${
    model.id
  }?tab=meeting`;
  const notifyModel = {
    id: AppUtils.udid(),
    ownerId: userId,
    ownerAvatar: AppUtils.makeUserAvatar(model, config),
    ownerName: user.nick,
    title: model.title,
    description: getTranslation(mes.joinToMeeting),
    createdAt: DateUtils.unixtime(),
    actorId: model.id,
    accId: model.accId,
    type: 'newMeeting',
    appId: model.appId,
    url,
  };

  yield createNotification({
    model: notifyModel,
    alert: true,
  });
}

function* wsNotify() {
  yield takeLatest(WS_CREATE_ACTOR, createActorNotification);
  yield takeLatest(WS_CREATE_ACCESS, createActorAccessNotification);
  yield takeLatest(WS_CREATE_REACTION, createReactionNotification);
  yield takeLatest(WS_CLEAR_ACTOR_NOTIFY, clearNotification);
  yield takeLatest(WS_SIP_JOIN, sipJoin);
}

export default wsNotify;
