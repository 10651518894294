import { defineMessages } from 'react-intl';

const m = defineMessages({
  recordMeeting: {
    id: 'recordMeeting',
    defaultMessage: 'Record Meeting',
  },
  startRecording: {
    id: 'startRecording',
    defaultMessage: 'Start Recording',
  },
  stopRecording: {
    id: 'stopRecording',
    defaultMessage: 'Stop Recording',
  },
  meetingRecorded: {
    id: 'meetingRecorded',
    defaultMessage: 'Meeting is currently being recorded',
  },
  noActiveRecords: {
    id: 'noActiveRecords',
    defaultMessage: 'No active recordings for this meeting',
  },
  recordingNotSupporting: {
    id: 'recordingNotSupporting',
    defaultMessage:
      'Recording of encrypted meetings is currently not supported',
  },
  recordingStarted: {
    id: 'recordingStarted',
    defaultMessage: 'This meeting is being recorded',
  },
});

export default m;
