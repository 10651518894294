import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  useMaybeLayoutContext,
  useRoomContext,
} from '@livekit/components-react';
import { Stack, Button, Label } from 'mw-style-react';
import { useIntl } from 'hooks';
import { RECORD_MEETING } from '@control-front-end/common/constants/meeting';
import mes from './intl';

const RECORDING = {
  start: 'start',
  stop: 'stop',
};

function SettingsMenu({ actorId, isRecording, setRecording }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [initialRecStatus, setInitialRecStatus] = useState(isRecording);
  const [processingRecRequest, setProcessingRecRequest] = useState(false);
  const layoutContext = useMaybeLayoutContext();
  const room = useRoomContext();

  useEffect(() => {
    if (initialRecStatus !== isRecording) {
      setProcessingRecRequest(false);
    }
  }, [isRecording, initialRecStatus]);

  const handleClose = () => {
    layoutContext?.widget.dispatch?.({ msg: 'toggle_settings' });
  };

  const toggleRoomRecording = () => {
    if (room.isE2EEEnabled) throw Error(t(mes.recordingNotSupporting));
    setProcessingRecRequest(true);
    setInitialRecStatus(isRecording);
    dispatch({
      type: RECORD_MEETING.REQUEST,
      payload: {
        actorId,
        action: isRecording ? RECORDING.stop : RECORDING.start,
      },
      callback: () => {
        setProcessingRecRequest(false);
        setRecording(!isRecording);
      },
    });
    handleClose();
  };

  return (
    <Stack.V fullWidth fullHeight>
      <Stack.H fullWidth justifyContent="spaceBetween" alignItems="center">
        <Label
          fontSize="large"
          fontWeight="semibold"
          value={t(mes.recordMeeting)}
        />
        <Button
          type={Button.TYPE.text}
          size={Button.SIZE.small}
          icon="close"
          rounded
          onClick={handleClose}
        />
      </Stack.H>
      <Stack.V>
        <Label
          value={isRecording ? t(mes.meetingRecorded) : t(mes.noActiveRecords)}
        />
        <Button
          type={isRecording ? Button.TYPE.error : Button.TYPE.quaternary}
          size={Button.SIZE.small}
          label={isRecording ? t(mes.stopRecording) : t(mes.startRecording)}
          visibility={processingRecRequest ? 'disabled' : 'visible'}
          onClick={() => toggleRoomRecording()}
        />
      </Stack.V>
    </Stack.V>
  );
}

SettingsMenu.propTypes = {
  actorId: PropTypes.string,
};

export default SettingsMenu;
