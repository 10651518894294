import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RequestStatus, TIMEFRAME_NAME } from 'constants';
import { WS_UPDATE_ACTOR } from '@control-front-end/common/constants/graphActors';
import {
  ADD_TRANSACTIONS_FILTER,
  GET_TRANSACTIONS_FILTER,
  GET_TRANSACTIONS_FILTERS,
  HIDE_TRANSACTIONS_FILTER,
  PARSE_TRANSACTIONS_FILTER,
  SYSTEM_TRANSACTIONS_FILTERS,
  UPDATE_TRANSACTIONS_FILTER,
} from '@control-front-end/common/constants/transactionsFilters';
import { FILTER_ACCESS } from '@control-front-end/common/constants/actorsFilters';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import { getUser } from '@control-front-end/common/sagas/users';
import { getActor, getItemsByIds } from './graph/graphNodes';

/**
 * Получить полную модель фильтра
 */
function* parseFilter({ payload, callback }) {
  const { filter = '{}' } = payload;
  const parsedFilter = JSON.parse(filter);
  const { actors, accounts, ownerId } = parsedFilter;
  const itemsToSearch = {
    accounts: accounts.map((i) => i.nameId),
    currencies: accounts.map((i) => i.currencyId),
    actors: actors || [],
  };
  try {
    const [namesResp, curResp, actorsResp] = yield call(getItemsByIds, {
      payload: { itemsToSearch },
    });
    parsedFilter.accounts = accounts.map(({ nameId, currencyId }) => ({
      id: AppUtils.createRid(),
      nameId,
      currencyId,
      accountName: namesResp.data.data.find((i) => i.id === nameId).name,
      currencyName: curResp.data.data.find((i) => i.id === currencyId).name,
    }));
    if (actorsResp && actorsResp.data.data.length) {
      parsedFilter.actors = actorsResp.data.data;
    }
    if (ownerId) {
      parsedFilter.owner = yield call(getUser, {
        payload: { userId: ownerId },
      });
      delete parsedFilter.ownerId;
    }
    if (parsedFilter.range && !parsedFilter.from) {
      const { from, to } = AppUtils.getRangeDate(parsedFilter.range, true);
      parsedFilter.from = from;
      parsedFilter.to = to;
    } else {
      parsedFilter.range = TIMEFRAME_NAME.custom;
    }
    if (callback) callback(parsedFilter);
    return parsedFilter;
  } catch (e) {
    return { error: e };
  }
}

/**
 * Изменение фильтра по WS
 */
function* wsUpdateFilter({ payload }) {
  const { model: updatedActor } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  if (updatedActor.accId !== accId) return;
  const { list } = yield select((state) => state.transactionsFilters);
  const findIndex = list.findIndex((i) => i.id === updatedActor.id);
  if (findIndex === -1) return;
  const copyList = structuredClone(list);
  const actorM = { ...copyList[findIndex] };
  copyList.splice(findIndex, 1, { ...actorM, ...updatedActor });
  yield put({
    type: UPDATE_TRANSACTIONS_FILTER.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Загрузить фильтры
 */
function* getFilters({ payload, callback }) {
  const { formId, loadMore, starred, localState } = payload;
  let filtersState;
  if (localState) {
    filtersState = localState;
  } else {
    filtersState = yield select((state) => state.transactionsFilters);
  }
  const { limit, offset, endList } = filtersState;
  if (endList) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/${formId}`,
    queryParams: {
      limit,
      offset,
      orderBy: 'created_at',
      orderValue: 'ASC',
      starred,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { list, total } = data.data;
  const curList = yield select((state) => state.transactionsFilters.list);
  const newActorsList = loadMore
    ? curList.concat(list)
    : [...SYSTEM_TRANSACTIONS_FILTERS, ...list];
  const systemCount = newActorsList.filter((i) => i.isSystem).length;
  const newPayload = {
    list: newActorsList,
    limit,
    offset: offset + limit,
    endList: newActorsList.length - systemCount === total,
    total,
    init: true,
  };
  if (localState) {
    callback(newPayload);
    return;
  }
  yield put({
    type: GET_TRANSACTIONS_FILTERS.SUCCESS,
    payload: newPayload,
  });
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Добавить фильтр в активный список
 */
function* addFilter({ payload, callback }) {
  const filters = yield select((state) => state.transactionsFilters);
  const { filtersFormId, actor } = payload;
  if (filtersFormId !== actor.formId && !actor.isSystem) return;
  const copyList = filters.list.slice();
  copyList.push(actor);
  yield put({
    type: ADD_TRANSACTIONS_FILTER.SUCCESS,
    payload: {
      list: copyList,
      offset: filters.offset + 1,
      total: filters.total + 1,
    },
  });
  if (callback) callback(actor);
}

/**
 * Убрать из активных фильтр
 */
function* hideFilter({ payload, callback }) {
  const filters = yield select((state) => state.transactionsFilters);
  const copyList = filters.list.filter((i) => i.id !== payload.id);
  yield put({
    type: HIDE_TRANSACTIONS_FILTER.SUCCESS,
    payload: {
      list: copyList,
      offset: filters.offset - 1 < 0 ? 0 : filters.offset - 1,
    },
  });
  if (callback) callback(payload);
}

/**
 * Изменить права доступа на фильтр
 */
function* manageFilterAccess({ payload }) {
  const { id, access } = payload;
  const { list } = yield select((state) => state.transactionsFilters);
  const copyList = structuredClone(list);
  const filterActor = copyList.find((i) => i.id === id);
  if (!filterActor) return;
  filterActor.access = access;
  yield put({
    type: FILTER_ACCESS.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Изменить форму в системном фильтре
 */
function* updateSystemFilter({ payload }) {
  const { id, title, formId, formTitle, formColor } = payload;
  const { list } = yield select((state) => state.transactionsFilters);
  const copyList = structuredClone(list);
  const findIndex = copyList.findIndex((i) => i.id === id);
  if (findIndex === -1) return;
  const sysFilter = {
    ...copyList[findIndex],
    title,
    formId,
    formTitle,
    formColor,
  };
  copyList.splice(findIndex, 1, sysFilter);
  yield put({
    type: UPDATE_TRANSACTIONS_FILTER.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Получить актор-фильтр
 */
export function* getFilter({ payload, callback, errorCallback }) {
  const actor = yield getActor({
    payload: { id: payload.actorId },
  });
  if (actor?.error && errorCallback) {
    errorCallback({ error: actor.title });
    return;
  }
  const parsedFilter = yield call(parseFilter, {
    payload: { filter: actor.data.filter },
  });
  if (parsedFilter.error && errorCallback) {
    errorCallback({ error: parsedFilter.error });
  }
  if (callback) callback({ actor, filter: parsedFilter });
}

function* transactionsFilters() {
  yield takeEvery(GET_TRANSACTIONS_FILTER.REQUEST, getFilter);
  yield takeEvery(PARSE_TRANSACTIONS_FILTER.REQUEST, parseFilter);
  yield takeEvery(GET_TRANSACTIONS_FILTERS.REQUEST, getFilters);
  yield takeEvery(ADD_TRANSACTIONS_FILTER.REQUEST, addFilter);
  yield takeEvery(HIDE_TRANSACTIONS_FILTER.REQUEST, hideFilter);
  yield takeEvery(FILTER_ACCESS.REQUEST, manageFilterAccess);
  yield takeEvery(UPDATE_TRANSACTIONS_FILTER.REQUEST, updateSystemFilter);
  yield takeEvery(WS_UPDATE_ACTOR, wsUpdateFilter);
}

export default transactionsFilters;
