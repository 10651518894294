import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Label, Tooltip, Icon, Clipboard, Button } from 'mw-style-react';

import AppUtils from '@control-front-end/utils/utils';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { GET_SYSTEM_ACTOR } from '@control-front-end/common/constants/graphActors';
import { GET_USER, CACHE_TTL, SET_DATA_CACHE } from 'constants';
import {
  CALL_DIRECTION,
  INIT_MEETING,
} from '@control-front-end/common/constants/meeting';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import { useIntl, useActorModalActions, useNotifications } from 'hooks';
import { checkUserPermissions, getCachedUser } from 'selectors';

import { CopyIdChip } from 'components';
import './UserPreview.scss';
import mes from '../SmartChipPreview/intl';

/**
 * Show user preview popup
 */
function UserPreview(props) {
  const { id, saId, title, withSystemActor = true, handleClose } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const showNotification = useNotifications();
  const user = useSelector((state) => getCachedUser(state, props));
  const config = useSelector((state) => state.config);
  const auth = useSelector((state) => state.auth);
  const accId = useSelector((state) => state.accounts.active);
  const checkPermissions = useSelector(checkUserPermissions);
  const canViewUsers = checkPermissions([PERMISSIONS.USERS_READONLY]);
  const { handleOpenActorModal } = useActorModalActions();
  const systemActorLink = user?.systemActorId
    ? document.location.origin +
      AppUtils.makeUrl(`/actors_graph/${accId}/view/${user.systemActorId}`)
    : null;

  /**
   * Cache user data to prevent multiple requests on chip hover
   */
  const cacheUser = (data) => {
    dispatch({
      type: SET_DATA_CACHE,
      payload: { objType: 'user', id, data },
    });
  };

  const setExistUserInfo = () => {
    cacheUser({
      id,
      value: title,
      avatar: AppUtils.makeUserAvatar({ id: saId || id, type: 'user' }, config),
    });
  };

  const fetchUser = () => {
    dispatch({
      type: GET_USER.REQUEST,
      payload: { userId: saId || id, type: saId ? 'sa' : undefined },
      callback: (data) => {
        if (!data?.id) {
          setExistUserInfo();
          return;
        }
        if (withSystemActor) {
          dispatch({
            type: GET_SYSTEM_ACTOR.REQUEST,
            payload: { objType: 'user', objId: data.id },
            callback: (actor) =>
              cacheUser({ ...data, systemActorId: actor.id }),
            errorCallback: () => cacheUser(data),
          });
        } else {
          cacheUser(data);
        }
      },
    });
  };

  const handleStartCall = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: INIT_MEETING.SUCCESS,
      payload: {
        callDirection: CALL_DIRECTION.OUT,
        participants: [auth.id, id],
      },
    });
  };

  useEffect(() => {
    const now = Date.now();
    const isRecentlyCached =
      user && +user.id === +id && now - user.reqTime < CACHE_TTL;
    if (isRecentlyCached) return;
    if (canViewUsers) {
      fetchUser();
    } else {
      setExistUserInfo();
    }
  }, [id, user, canViewUsers]);

  if (!user) return null;
  const { value: nick, avatar, type, email, systemActorId } = user;
  const avatarLarge = avatar.replace(/\/(\d{2}x\d{2})\//, '/64x64/'); // NOSONAR

  return (
    <div styleName="preview__root">
      <UserAvatar
        size={canViewUsers ? 'xlarge' : 'medium'}
        src={avatarLarge}
        label={nick}
        userType={type}
      />
      <div styleName="preview__actions">
        {systemActorId ? (
          <>
            <Tooltip value={t(mes.expandProfile)}>
              <Icon
                type="profile"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenActorModal(systemActorId);
                  handleClose();
                }}
              />
            </Tooltip>
            <Tooltip value={t(mes.copyLink)}>
              <Icon
                type="copy"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  Clipboard.copy(systemActorLink);
                  showNotification.success(t(mes.linkCopied));
                }}
              />
            </Tooltip>
            <div
              onClick={(e) => {
                e.stopPropagation();
                window.open(systemActorLink);
              }}
            >
              <Icon size="small" type="link_external" />
            </div>
          </>
        ) : null}
        <Icon
          size="small"
          type="close"
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        />
      </div>
      <div styleName="preview__info">
        <Label fontWeight="semibold" value={nick} />
        <Stack.H alignItems="center" size="none">
          <CopyIdChip fontSize="small" type="default" id={id} />
          <Tooltip value={t(mes.startCall)}>
            <Button
              type={Button.TYPE.tertiary}
              size={Button.SIZE.xsmall}
              rounded
              icon="call"
              visibility={
                auth.id === id || !config?.livekitUrl ? 'hidden' : 'visible'
              }
              onClick={handleStartCall}
            />
          </Tooltip>
        </Stack.H>
        {type === 'user' && canViewUsers && email ? (
          <Label value={email} />
        ) : null}
      </div>
    </div>
  );
}

UserPreview.propTypes = {
  id: PropTypes.string,
  withSystemActor: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default UserPreview;
