import { takeEvery, select, put, call } from 'redux-saga/effects';
import { sortBy, indexOf, map } from 'lodash';
import {
  GET_ACTOR_GLOBAL_LINKS,
  UPDATE_ACTOR_VIEW,
} from '@control-front-end/common/constants/graphActors';
import {
  GET_MEETING_PARTICIPANTS,
  WS_SIP_JOIN,
  WS_SIP_LEFT,
} from '@control-front-end/common/constants/meeting';
import api from '@control-front-end/common/sagas/api';
import { RequestStatus } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { makeActorPicture } from './graph/graphHelpers';

/**
 * Update actor
 */
export function* updateActorView({ payload, callback }) {
  const actorViewState = yield select((state) => state.actorView);
  const actorView = structuredClone(actorViewState);
  const actorData = structuredClone(payload.actorData);
  if (actorView.id !== actorData.id) return;
  if (actorData.attachments && actorData.attachments.length) {
    const config = yield select((state) => state.config);
    for (const attach of actorData.attachments) {
      attach.userAvatar = AppUtils.makeUserAvatar(attach, config);
    }
  }
  if (actorData.actors) {
    actorData.events = actorData.actors;
  }
  if (actorData.picture) {
    actorData.pictureUrl = yield call(makeActorPicture, actorData);
  }
  if (actorData.invites) {
    const pureRules = (actorView.access || []).filter((i) => !i.isInvite);
    actorData.access = [...pureRules, ...actorData.invites];
    delete actorData.invites;
  } else if (actorData.access) {
    const currentInvites = (actorView.access || []).filter((i) => !!i.isInvite);
    actorData.access.push(...currentInvites);
  }
  yield put({
    type: UPDATE_ACTOR_VIEW.SUCCESS,
    payload: { ...actorView, ...actorData },
  });
  if (callback) callback();
  return actorData;
}

/**
 * Get all connected layers to an actor
 */
function* getGlobalActorLinks({ payload, callback }) {
  const { actorId, q, limit = 15, offset = 0 } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/layers_links/actor_global/${actorId}`,
    queryParams: { limit, offset, q },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_ACTOR_GLOBAL_LINKS.SUCCESS });
  callback(data.data);
}

/**
 * Sort online meeting participants
 */
function* wsMeetingParticipants({ payload }) {
  const { model, participants } = payload;
  const actorViewState = yield select((state) => state.actorView);
  const actorView = structuredClone(actorViewState);
  if (actorView.id !== model.id) return;
  const sortOrder = map(participants, 'id');
  const sorting = (access) =>
    sortBy(access, (item) => {
      const index = indexOf(sortOrder, item.userId);
      item.online = index !== -1;
      return index === -1 ? Number.MAX_SAFE_INTEGER : index;
    });
  yield put({
    type: UPDATE_ACTOR_VIEW.SUCCESS,
    payload: {
      ...actorView,
      access: sorting(actorView.access),
      defaultAccess: sorting(actorView.defaultAccess),
    },
  });
}

function* actorViewSaga() {
  yield takeEvery(UPDATE_ACTOR_VIEW.REQUEST, updateActorView);
  yield takeEvery(GET_ACTOR_GLOBAL_LINKS.REQUEST, getGlobalActorLinks);
  yield takeEvery(GET_MEETING_PARTICIPANTS.SUCCESS, wsMeetingParticipants);
  yield takeEvery(WS_SIP_JOIN, wsMeetingParticipants);
  yield takeEvery(WS_SIP_LEFT, wsMeetingParticipants);
}

export default actorViewSaga;
