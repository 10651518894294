import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label, Stack, Tooltip } from 'mw-style-react';
import { useIntl, useSearchList, useDebouncedEffect } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import { GET_ACTORS_ACCOUNTS } from '@control-front-end/common/constants/actorAccounts';
import { SEARCH_DEBOUNCE_INTERVAL } from 'constants';
import SelectEntityField from '@control-front-end/common/components/SelectEntityField';
import scss from './SelectActorsAccountPair.scss';
import mes from './intl';

/**
 * Formatted account balance
 */
const getBalance = (account) => {
  const { currencyType, precision, symbol, currencyName } = account;
  const currencyParams = { type: currencyType, symbol, precision };
  return `${AppUtils.simpleFormattedAmount(
    account.amount,
    currencyParams
  )} ${currencyName}`;
};

/**
 * Render account balance with tooltip
 */
function Balance({ balance }) {
  return (
    <Tooltip styleName="account__balance__tooltip" value={balance}>
      <Label styleName="account__balance" value={balance} />
    </Tooltip>
  );
}

const findAccount = (accounts, { nameId, currencyId } = {}) => {
  if (!nameId || !currencyId) return null;

  return (
    accounts?.find(
      (acc) =>
        acc.nameId === nameId && Number(acc.currencyId) === Number(currencyId)
    ) || null
  );
};

const addAccountTotal = (acc) => {
  if (!acc?.debit || !acc?.credit) return acc;
  return {
    ...acc,
    total: {
      ...acc.debit,
      ...{ amount: acc.credit.amount - acc.debit.amount },
    },
  };
};

/**
 * Components for selecting actors' account
 * @returns {JSX.Element}
 * @constructor
 */
function SelectActorsAccountPair({
  actorId,
  nameId,
  currencyId,
  accountType,
  incomeType,
  currency,
  onChange,
  fieldClassName,
  errorCallback,
  showBalance,
  disableSystemAccounts = false,
  filter,
  preselectedAccount = null,
  ...props
}) {
  const t = useIntl();
  const isFirstRenderRef = useRef(true);
  const [isFocused, setFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedQuery = useDebouncedEffect(
    searchQuery,
    SEARCH_DEBOUNCE_INTERVAL
  );

  const { list, onScroll, makeSearch } = useSearchList({
    actionType: GET_ACTORS_ACCOUNTS.REQUEST,
    settings: { step: 12, scrollToLoad: 20 },
    skip: !actorId,
    // turn off strict check list.length < limit
    // because getActorsAccounts saga returns 1 item merged from 2 of api response
    // e.g. with step 10 we get 5 items and strict check counts this as the end of list
    strictEndCheck: false,
    localState: true,
    actorId,
    accountType,
    errorCallback,
  });

  useEffect(() => {
    if (!actorId) return;
    makeSearch({ actorId });
  }, [actorId]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
    if (isFocused) makeSearch({ actorId, query: debouncedQuery || null });
  }, [debouncedQuery]);

  const actorAccounts = useMemo(
    () => list.filter(filter).map(addAccountTotal),
    [list]
  );

  const [activeAccount, setActiveAccount] = useState(null);

  useEffect(() => {
    const actorAccount =
      findAccount(actorAccounts, {
        nameId,
        currencyId,
      }) || addAccountTotal(preselectedAccount);

    setActiveAccount(actorAccount);
  }, [actorAccounts, nameId, currencyId]);

  const defaultTitle = activeAccount
    ? `${activeAccount.accountName}, ${activeAccount.currencyName}`
    : '';

  const itemsList = actorAccounts.length
    ? actorAccounts.map(
        ({ key, accountName, currencyName, privs, isSystem }) => ({
          id: key,
          value: key,
          title: `${accountName}, ${currencyName}`,
          privs,
          isSystem,
        })
      )
    : [
        {
          title: currency?.name
            ? t(mes.noAccountsWithCurrency, {
                currencyName: currency.name,
              })
            : t(mes.noAccounts),
          privs: {},
        },
      ];

  return (
    <Stack.H
      className={cn(fieldClassName, scss.account)}
      fullWidth
      size={Stack.SIZE.xxsmall}
      alignItems="center"
    >
      <SelectEntityField
        {...props}
        objType="account"
        list={itemsList}
        value={{ id: activeAccount?.key, title: defaultTitle }}
        autoSelect={false}
        fullModel
        popoverOnTop
        bordered
        searchTitle={() => {}}
        placeholder={t(mes.selectAccount)}
        onSearch={(value) => {
          setSearchQuery(value);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        resetOnBlur
        onScroll={onScroll}
        onCreate={null}
        onChange={({ value }) => {
          const newValue = actorAccounts.find((i) => value?.id === i.key);
          if (newValue) onChange(newValue);
        }}
        checkItemVisibility={({ privs, isSystem }) =>
          (privs && !privs.modify) || (isSystem && disableSystemAccounts)
            ? 'disabled'
            : 'visible'
        }
        fieldClassName={cn(scss.select, { [scss.showBalance]: showBalance })}
      />
      {activeAccount?.[incomeType] && showBalance ? (
        <Balance balance={getBalance(activeAccount[incomeType])} />
      ) : null}
    </Stack.H>
  );
}

SelectActorsAccountPair.defaultProps = {
  accountType: 'fact',
  fieldClassName: '',
  visibility: 'visible',
  showBalance: true,
  filter: () => true,
};

SelectActorsAccountPair.propTypes = {
  value: PropTypes.object,
  actorId: PropTypes.string,
  currencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameId: PropTypes.string,
  visibility: PropTypes.string,
  accountType: PropTypes.string,
  incomeType: PropTypes.string,
  withPrivs: PropTypes.bool,
  liveSearch: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorCallback: PropTypes.func,
  filter: PropTypes.func,
  preselectedAccount: PropTypes.object,
  disableSystemAccounts: PropTypes.bool,
};

export default SelectActorsAccountPair;
