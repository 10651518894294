import { Utils } from 'mw-style-react';

export const MANAGE_UI_SPLITTER = ':';

export const MANAGE_UI_LIST_SPLITTER = '_';

export const URL_PARAMS = {
  /**
   * Works the same way as a plainMode from store.settings but with higher priority.
   * Value type: Boolean
   */
  plainMode: 'plainMode',

  /**
   * Simplifies interface and removes optional UI elements for better focusing
   * Value type: Boolean
   */
  focusMode: 'focusMode',

  /**
   * Allows enabling or disabling the double-click event functionality
   */
  eventDoubleClick: 'eventDoubleClick',

  /**
   * Controls whether the actor view should be opened on mobile devices after a click or double-click event.
   */
  openActorViewInMobile: 'openActorViewInMobile',

  /**
   * Activates the sending of webhooks to the user's API.
   */
  webhooks: 'webhooks',

  /**
   * Holds the list of elements that should be hidden or displayed in UI, split by "_" symbol
   * @notice has higher priority than focusMode
   * Value type: Boolean|String
   * @example sidebar_true:streamsControls_false:eventTabs_chat_details_info:reactionOptions_smiles_files
   *
   * For possible options
   * `@see packages/common/constants/togglingUIElements.js
   *
   * Which represents an object with the following settings:
   * {
   *  sidebar: true, // Display sidebar
   *  streamsControls: false, // Hide streams controls
   *  hideEditReactionBtn: false, // Hide Edit button on reactions
   *  hideDeleteReactionBtn: false, // Disable Remove button on reactions
   *  eventTabs: ['chat', 'details', 'info'], // Custom tabs list for event (available tabs see in constants/ACTOR_TABS)
   *  headerControls: true, // Display header controls
   *  customHeaderControls: ['search', 'calendar', 'split', 'addEvent', 'label'] // Display specific header controls.
   *  reactionOptions: ['smiles', 'files'], // Show only smiles and files buttons in reaction editor
   *  history: false, // Hide history tab near the comments in plainMode
   * }
   */
  manageUI: 'manageUI',
  /**
   * Represents an active actor tab from URL search params
   */
  tab: 'tab',
};

/**
 * This params used for UI customization and should be saved between history routings.
 * @see packages/app/src/store/history.js
 *
 * Used by "support type" customers to minify Events page UI with one link only
 *
 * Different params combination examples:
 * 1) plainMode=true&focusMode=true&manageUI=eventTabs_chat_details:eventInfo_true:eventControls_true
 * 2) focusMode=true&manageUI=sidebar_true:history_false
 * 3) plainMode=true&manageUI=eventTabs_false:reactionOptions_smiles_files_rating
 */
export const SUPPORT_URL_PARAMS = Utils.pick(URL_PARAMS, [
  URL_PARAMS.focusMode,
  URL_PARAMS.plainMode,
  URL_PARAMS.manageUI,
  URL_PARAMS.eventDoubleClick,
  URL_PARAMS.openActorViewInMobile,
  URL_PARAMS.webhooks,
]);
