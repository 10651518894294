import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  ACCEPT_MEETING,
  CALL_DIRECTION,
  CALL_STATUS,
  CREATE_MEETING,
  END_MEETING,
  INIT_MEETING,
  JOIN_MEETING,
  MEETING_NOTIFY,
  REJECT_MEETING,
  GET_MEETING_PARTICIPANTS,
  WS_SIP_CALL,
  WS_SIP_JOIN,
  WS_SIP_REJECT,
  RECORD_MEETING,
} from '@control-front-end/common/constants/meeting';
import {
  NOTIFY_LEVEL,
  RequestStatus,
  SHOW_NOTIFY,
  SOUND_TYPE,
} from 'constants';
import getTranslation from '@control-front-end/utils/getTranslation';
import AppUtils from '@control-front-end/utils/utils';
import mes from 'globalIntl';
import api from './api';

/**
 * Join to the meeting room
 */
function* joinMeeting({ payload, callback }) {
  const { actorId } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/join/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield put({ type: JOIN_MEETING.SUCCESS, payload: data.data });
}

/**
 * Create new meeting room
 */
function* createMeeting({ payload, callback }) {
  const { participants, parentActorId } = payload;
  const accounts = yield select((state) => state.accounts);
  AppUtils.makeSound(SOUND_TYPE.calling, { loop: true });

  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/create/${accounts.active}`,
    body: { participants, parentActorId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield put({ type: CREATE_MEETING.SUCCESS, payload: data.data });
}

/**
 * A generator function that sends a server notification related to a meeting state.
 */
function* meetingServerNotification({ type, notify }) {
  const meeting = yield select((state) => state.meeting);
  if (!meeting) return;
  yield put({ type });
  yield call(api, {
    method: 'post',
    url: `/sip/notification/${meeting.actorId}/${notify}`,
  });
}

/**
 * Get meeting participants
 */
function* getMeetingParticipants({ payload, callback }) {
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/participants/${payload.actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield put({
    type: GET_MEETING_PARTICIPANTS.SUCCESS,
    payload: { participants: data.data, model: { id: payload.actorId } },
  });
}

/**
 * Start/stop meeting recording
 */
function* recordingMeeting({ payload, callback }) {
  const { actorId, action } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/sip/room/recording/${actorId}/${action}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield put({ type: RECORD_MEETING.SUCCESS });
}

/**
 * Handles accepting a meeting by generating a notification to the meeting server.
 */
function* acceptMeeting() {
  yield meetingServerNotification({
    type: ACCEPT_MEETING.SUCCESS,
    notify: MEETING_NOTIFY.ACCEPT,
  });
}

/**
 * Generator function to handle the rejection of a meeting.
 */
function* rejectMeeting() {
  yield meetingServerNotification({
    type: REJECT_MEETING.SUCCESS,
    notify: MEETING_NOTIFY.REJECT,
  });
}

/**
 * WS new meeting room created
 */
function* wsMeetingCall({ payload }) {
  const { user, model } = payload;
  const accounts = yield select((state) => state.accounts);
  const auth = yield select((state) => state.auth);
  if (accounts.active !== model.accId) return;
  const callDirection =
    auth.id === user.id ? CALL_DIRECTION.OUT : CALL_DIRECTION.IN;
  yield put({
    type: INIT_MEETING.SUCCESS,
    payload: {
      callDirection,
      status: CALL_STATUS.calling,
      actor: model,
      actorId: model.id,
      user,
      participants: [auth.id, user.id],
    },
  });
}

/**
 * WS join call
 */
function* wsMeetingJoin({ payload }) {
  const { user, model } = payload;
  const auth = yield select((state) => state.auth);
  const meeting = yield select((state) => state.meeting);
  if (auth.id === user.id || meeting?.actorId !== model.id) return;
  AppUtils.stopSound();
  yield put({ type: ACCEPT_MEETING.SUCCESS });
}

/**
 * WS reject call
 */
function* wsMeetingReject({ payload }) {
  const { user, model } = payload;
  const auth = yield select((state) => state.auth);
  const meeting = yield select((state) => state.meeting);
  if (meeting?.actorId !== model.id) return;
  AppUtils.stopSound();
  yield put({ type: END_MEETING.SUCCESS });
  if (auth.id !== user.id) {
    yield put({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: 'rejectCall',
        type: NOTIFY_LEVEL.ERROR,
        label: getTranslation(mes.rejectedMeetingCall),
      },
    });
  }
}

function* meeting() {
  yield takeEvery(JOIN_MEETING.REQUEST, joinMeeting);
  yield takeEvery(CREATE_MEETING.REQUEST, createMeeting);
  yield takeEvery(ACCEPT_MEETING.REQUEST, acceptMeeting);
  yield takeEvery(REJECT_MEETING.REQUEST, rejectMeeting);
  yield takeEvery(GET_MEETING_PARTICIPANTS.REQUEST, getMeetingParticipants);
  yield takeEvery(RECORD_MEETING.REQUEST, recordingMeeting);
  yield takeEvery(WS_SIP_CALL, wsMeetingCall);
  yield takeEvery(WS_SIP_JOIN, wsMeetingJoin);
  yield takeEvery(WS_SIP_REJECT, wsMeetingReject);
}

export default meeting;
