import {
  call,
  delay,
  put,
  select,
  take,
  takeEvery,
  actionChannel,
  debounce,
} from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import {
  SUBSCRIPTION_INTERVAL,
  SUBSCRIBE_GRAPH,
  SUBSCRIBE_BALANCES,
  UNSUBSCRIBE_ALL_GRAPH,
  GRAPH_REALTIME_PROLONGATION,
  WS_LAYER_CHANGED,
  WS_UPDATE_BALANCES,
  WS_DELETE_LAYER,
  WS_UPDATE_ACTOR_ON_LAYER,
  WS_ACTOR_BALANCE_CHANGED,
} from '@control-front-end/common/constants/graphRealtime';
import {
  CLOSE_LAYER,
  GET_LAYER,
  UPDATE_LAYER,
  SYSTEM_LAYERS_NAMES,
} from '@control-front-end/common/constants/graphLayers';
import {
  UPDATE_ACTOR,
  REMOVE_ACTOR,
  GET_ACTORS_BALANCE,
  CREATE_ACTOR_LIST,
  UPDATE_ACTOR_LIST,
  REMOVE_ACTOR_LIST,
  WS_CREATE_ACTOR,
  WS_DELETE_ACTOR,
  WS_UPDATE_ACTOR,
  UPDATE_ACTOR_VIEW,
  WS_CREATE_EDGE,
  WS_DELETE_EDGE,
} from '@control-front-end/common/constants/graphActors';
import {
  RequestStatus,
  SET_MODAL,
  WS_CREATE_ACCESS,
  WS_UPDATE_ACCESS,
  WS_DELETE_ACCESS,
  WS_GRAPH_SUBSCRIBERS,
} from 'constants';
import { GET_STREAMS_UNREAD_COUNTERS } from '@control-front-end/common/constants/streams';
import { Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import api from '@control-front-end/common/sagas/api';
import history from '@control-front-end/app/src/store/history';
import {
  makeGraphModels,
  updateActorOnGraph,
  sendReducerMsg,
  isActiveTemplateList,
  isUsersRecentActor,
  extendModelWithAvatars,
  getActorByGraphId,
  getActiveLayer,
} from './graphHelpers';
import { refetchElements } from './graphDiscovery';
import { updateActorView } from '../actorView';
import { removeEdge } from './graphEdges';

/**
 * Unsubscribe balance changes
 */
function* subUnsubBalances(status) {
  const graphRealtimeState = yield select((state) => state.graphRealtime);
  const workspaces = yield select((state) => state.accounts);
  const nodeIds = graphRealtimeState.balances;
  if (!nodeIds.length) return;
  const { result } = yield call(api, {
    method: 'post',
    url: `/realtime/balance_subscribe/${workspaces.active}/${status}`,
    body: nodeIds,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: SUBSCRIBE_BALANCES.SUCCESS,
    payload: status === 'on' ? nodeIds : [],
  });
}

/**
 * Subscribe\Unsubscribe all notifications
 */
function* subUnsub(status) {
  const graphRealtimeState = yield select((state) => state.graphRealtime);
  for (const sub of graphRealtimeState.layers) {
    yield put({ type: SUBSCRIBE_GRAPH.REQUEST, payload: { ...sub, status } });
  }
  yield subUnsubBalances(status);
}

/**
 * Save layer changes
 */
function* saveLayerChanges({ copyList, layer, layerIndex, data }) {
  const { nodes, edges } = yield call(makeGraphModels, {
    nodes: data.data.nodes,
    edges: data.data.edges,
    replaceAll: true,
  });
  const newLayerModel = {
    ...layer,
    nodes,
    edges,
  };
  copyList.splice(layerIndex, 1, newLayerModel);
  yield put({
    type: GET_LAYER.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Get object for subscribe
 */
function getSubObj({ layerId, typeLayer }) {
  let obj;
  let id;
  switch (typeLayer) {
    case 'layers':
      obj = 'layer';
      id = layerId;
      break;
    case 'actors':
      obj = 'actor';
      id = layerId;
      break;
    case 'trees':
      obj = 'accountTree';
      id = layerId.split('_')[1];
      break;
    default:
  }
  return { id, obj };
}

/**
 * Get layer by id
 */
export function* getLayer(layerId) {
  const graphL = yield select((state) => state.graphLayers);
  const copyList = graphL.list.slice();
  const layerIndex = copyList.findIndex((i) => i.id === layerId);
  if (layerIndex === -1) return false;
  return {
    copyList,
    layer: copyList[layerIndex],
    layerIndex,
    isActive: graphL.active === layerId,
  };
}

/**
 * Update changed inactive layer
 */
function* updateInactiveLayer({ layer, layerIndex, copyList }) {
  const layerCopy = { ...layer };
  delete layerCopy.nodes;
  delete layerCopy.edges;
  copyList.splice(layerIndex, 1, layerCopy);
  yield put({ type: UPDATE_LAYER.SUCCESS, payload: { list: copyList } });
  const subObj = getSubObj({ layerId: layer.id, typeLayer: layer.typeLayer });
  yield put({
    type: SUBSCRIBE_GRAPH.REQUEST,
    payload: { ...subObj, status: 'off' },
  });
}

/**
 * Existing balances actualization
 */
function mergeExistBalances({ layer, data }) {
  const actors = layer.nodes.filter((i) => i.data.type === 'node');
  const newActorIds = [];
  data.data.nodes.forEach((node) => {
    const fNode = actors.find((i) => i.data.actorId === node.id);
    if (fNode && fNode.data) {
      const { balance, balanceVector } = fNode.data;
      node.balance = balance;
      node.balanceVector = balanceVector;
    } else {
      newActorIds.push(node.id);
    }
  });
  const actorIds = actors.map((i) => i.data.actorId).concat(newActorIds);
  return { actorIds, newActorIds };
}

/**
 * Get new actors balances
 */
export function* getNewBalances({ layerId, actorIds }) {
  if (!actorIds.length) return;
  const { balances } = yield select((state) => state.graphRealtime);
  let balanceFilter;
  if (balances.length) {
    balanceFilter = balances[0];
  } else {
    const filtersLS = Utils.fromStorage('actors_layer_filters');
    balanceFilter = filtersLS ? JSON.parse(filtersLS) : {};
  }
  const { nameId, currencyId, from, to } = balanceFilter;
  yield put({
    type: GET_ACTORS_BALANCE.REQUEST,
    payload: {
      layerId,
      actorIds,
      currencyId: currencyId !== 'none' ? currencyId : null,
      nameId: nameId !== 'none' ? nameId : null,
      from,
      to,
    },
  });
}

/**
 * Update subscription to layer balances
 */
export function* resubscribeBalances({ layerId }) {
  const { balances } = yield select((state) => state.graphRealtime);
  if (!balances.length) return;
  const { nameId, currencyId } = balances[0];
  yield put({
    type: SUBSCRIBE_BALANCES.REQUEST,
    payload: { layerId, nameId, currencyId, status: 'on' },
  });
}

/**
 * Request and resubscribe to balances after WS update
 */
function* wsUpdateBalances({ payload }) {
  const { layerId, actorIds } = payload;
  yield getNewBalances({ layerId, actorIds });
  yield resubscribeBalances({ layerId });
}

/**
 * Balances update algorithm depending on changes type
 */
function* manageBalancesAfterWsChanges(payload) {
  const { layerId, actorIds } = payload;
  if (actorIds.length) {
    // if actors are added/removed - update immediately
    yield call(wsUpdateBalances, {
      payload: { layerId, actorIds },
    });
  } else {
    // else add action to interval update buffer
    yield put({
      type: WS_UPDATE_BALANCES,
      payload: { layerId, actorIds },
    });
  }
}

/**
 * Changes for accounts layer
 */
function* accountTreeChanges({ edgeTypeId, isActiveTabChange }) {
  const graphL = yield select((state) => state.graphLayers);
  const copyList = graphL.list.slice();
  const trees = AppUtils.findAllIndexes(copyList, (i) =>
    i.id.includes(`_${edgeTypeId}`)
  );
  for (const layerIndex of trees) {
    const layer = copyList[layerIndex];
    if (layer.id !== graphL.active) {
      delete layer.nodes;
      delete layer.edges;
      copyList.splice(layerIndex, 1, layer);
    } else if (isActiveTabChange) {
      return;
    } else {
      const edgeTypes = yield select((state) => state.edgeTypes);
      const edgeTypeH = edgeTypes.find((i) => i.id === edgeTypeId);
      if (!edgeTypeH) return;
      const actorId = graphL.active.split('_')[0];
      const { result, data } = yield call(api, {
        method: 'get',
        url: `/graph/tree/${actorId}`,
        queryParams: { edgeTypeId: edgeTypeH.id },
      });
      if (result !== RequestStatus.SUCCESS) return;
      const { actorIds } = yield mergeExistBalances({ layer, data });
      yield saveLayerChanges({ copyList, layer, layerIndex, data });
      // request all actors balances because they are calculated up the tree
      yield call(wsUpdateBalances, {
        payload: { layerId: graphL.active, actorIds },
      });
    }
  }
  yield put({ type: UPDATE_LAYER.SUCCESS, payload: { list: copyList } });
}

/**
 * Common layer changes
 */
function* layerChanges({ layerId, isActiveTabChange }) {
  if (isActiveTabChange) return;

  yield refetchElements({ payload: { layerId } });
}

/**
 * Changes for actor layer
 */
function* actorChanges({ actorId, isActiveTabChange }) {
  const res = yield getLayer(actorId);
  if (!res) return;
  const { copyList, layer, layerIndex, isActive } = res;
  if (!isActive) {
    yield updateInactiveLayer({ layer, layerIndex, copyList });
    return;
  }
  if (isActiveTabChange) return;
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === 'hierarchy');
  if (!edgeTypeH) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph/linked_actors/${actorId}`,
    queryParams: { edgeTypeId: edgeTypeH.id },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { newActorIds } = yield mergeExistBalances({ layer, data });
  yield saveLayerChanges({ copyList, layer, layerIndex, data });
  yield manageBalancesAfterWsChanges({
    layerId: actorId,
    actorIds: newActorIds,
  });
}

// =============================================================================

/**
 * Apply graph changes
 */
function* layerChanged({ payload }) {
  const { actorId: id, obj, tabId } = payload;
  const appInit = yield select((state) => state.appInit);
  const isActiveTabChange = appInit.tabId === tabId;
  switch (obj) {
    case 'layer':
      yield call(layerChanges, { layerId: id, isActiveTabChange });
      break;
    case 'actor':
      yield call(actorChanges, { actorId: id, isActiveTabChange });
      break;
    case 'accountTree':
      yield call(accountTreeChanges, { edgeTypeId: id, isActiveTabChange });
      break;
    default:
  }
}

/**
 * Apply actor update on layer
 */
function* updateActorOnLayer({ payload }) {
  const auth = yield select((state) => state.auth);
  const hasAccess = payload.access.some((i) => i.userId === auth.id);
  const appInit = yield select((state) => state.appInit);
  if (!hasAccess || (payload.tabId && appInit.tabId === payload.tabId)) return;
  const graphL = yield select((state) => state.graphLayers);
  const layersWithActor = graphL.list.filter(
    (i) => i.nodes && i.nodes.find((node) => node.data.actorId === payload.id)
  );
  if (!layersWithActor.length) return;
  const newActor = payload;
  yield call(extendModelWithAvatars, newActor);
  for (const layer of layersWithActor) {
    const { nodes } = yield call(updateActorOnGraph, {
      actorModel: newActor,
      layerId: layer.id,
    });
    yield sendReducerMsg({
      layerId: layer.id,
      type: UPDATE_ACTOR.SUCCESS,
      payload: { nodes, editableElement: null },
    });
  }
}

/**
 * Close deleted layer
 */
function* layerDeleted({ payload }) {
  const { id: layerId } = payload;
  const graphL = yield select((state) => state.graphLayers);
  const layer = graphL.list.find((i) => i.id === layerId);
  if (!layer) return;
  yield put({
    type: SET_MODAL,
    payload: {
      name: 'LayerDeleted',
      data: { layerId, layerName: layer.name },
    },
  });
}

/**
 * Apply actor balances changes
 */
function* balancesChanged({ payload }) {
  const { nameId, currencyId, changed } = payload;
  const activeLayer = yield getActiveLayer();
  if (!activeLayer?.nodes?.length) return;
  const ids = changed.map((i) => i.actorId);
  const actorIds = activeLayer.nodes
    .filter((i) => ids.includes(i.data.actorId) && i.status !== 'removed')
    .map((i) => i.data.actorId);
  if (!actorIds.length) return;
  yield put({
    type: GET_ACTORS_BALANCE.REQUEST,
    payload: {
      layerId: activeLayer.id,
      nameId,
      currencyId,
      actorIds,
    },
  });
}

/**
 * Actors balances changes queue
 */
function* wsBalancesChanged() {
  const requestChan = yield actionChannel(WS_ACTOR_BALANCE_CHANGED);
  while (true) {
    try {
      const { payload } = yield take(requestChan);
      yield call(balancesChanged, { payload });
      yield delay(200);
    } catch (e) {
      console.error(e); // eslint-disable-line
      break;
    }
  }
}

/**
 * Confirm layer subscription by interval
 */
function* runSubProlongation() {
  yield delay(SUBSCRIPTION_INTERVAL);
  yield subUnsub('on');
  yield put({ type: GRAPH_REALTIME_PROLONGATION });
}

/**
 * Unsubscribe all subscriptions
 */
function* unsubscribeAllGraph() {
  yield subUnsub('off');
  yield put({ type: UNSUBSCRIBE_ALL_GRAPH.SUCCESS, payload: {} });
}

/**
 * Subscribe\unsubscribe realtime layer changes
 */
function* subscribeGraph({ payload }) {
  const { id, obj, status } = payload;
  if (obj === 'layer' && SYSTEM_LAYERS_NAMES[id]) return;
  const workspaces = yield select((state) => state.accounts);
  const { result } = yield call(api, {
    method: 'post',
    url: `/realtime/graph_subscribe/${workspaces.active}/${status}`,
    body: { id, obj },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const graphRealtimeState = yield select((state) => state.graphRealtime);
  let layerList;
  if (status === 'on') {
    const copyLayers = graphRealtimeState.layers.slice();
    const fEl = copyLayers.find((i) => i.id === id && i.obj === obj);
    if (!fEl) copyLayers.push({ id, obj });
    layerList = copyLayers;
  } else {
    layerList = graphRealtimeState.layers.filter(
      (i) => `${i.id}_${i.obj}` !== `${id}_${obj}`
    );
  }
  yield put({ type: SUBSCRIBE_GRAPH.SUCCESS, payload: layerList });
}

/**
 * Set graph subscribers
 */
function* wsGraphSubscribers({ payload }) {
  const { id, obj, users } = payload;
  const config = yield select((state) => state.config);
  const graphRealtimeState = yield select((state) => state.graphRealtime);
  const layerList = graphRealtimeState.layers.filter(
    (i) => `${i.id}_${i.obj}` !== `${id}_${obj}`
  );
  users.forEach((i) => {
    i.avatar = AppUtils.makeUserAvatar(i, config);
  });
  layerList.push({ id, obj, users });
  yield put({ type: SUBSCRIBE_GRAPH.SUCCESS, payload: layerList });
}

/**
 * Subscribe\unsubscribe realtime balances changes
 */
function* subscribeBalances({ payload }) {
  const { nameId, currencyId, layerId, status } = payload;
  const workspaces = yield select((state) => state.accounts);
  const graphL = yield select((state) => state.graphLayers);
  const layer = graphL.list.find(
    (i) => i.id === layerId || i.layerId === layerId
  );
  if (!layer) return;
  yield subUnsubBalances('off');
  const isException = layer.isSystem && SYSTEM_LAYERS_NAMES[layerId];
  if (!nameId || !currencyId || isException) return;
  const copyNodes = layer.nodes ? layer.nodes.slice() : [];
  const nodeIds = copyNodes
    .filter((i) => i.data.type === 'node')
    .map((a) => ({ actorId: a.data.actorId, nameId, currencyId }));
  if (nodeIds.length) {
    const { result } = yield call(api, {
      method: 'post',
      url: `/realtime/balance_subscribe/${workspaces.active}/${status}`,
      body: nodeIds,
    });
    if (result !== RequestStatus.SUCCESS) return;
  }
  yield put({ type: SUBSCRIBE_BALANCES.SUCCESS, payload: nodeIds });
}

/**
 * Check if we need to handle WS packet
 */
function* isHandleWSPacket(payload) {
  const accounts = yield select((state) => state.accounts);
  const { accId } = payload.model;
  return accounts.active === accId;
}

/**
 * Calls request for stream counters
 */
function* callStreamCounters({ payload }) {
  const auth = yield select((state) => state.auth);
  const appInit = yield select((state) => state.appInit);
  const systemForms = yield select((state) => state.systemForms);
  const actorsList = yield select((state) => state.actorsList);
  const { tabId, involvedUsers, privs, model } = payload;
  if (
    (tabId && appInit.tabId === tabId) ||
    systemForms.events.id !== actorsList.formId ||
    (involvedUsers && !involvedUsers.includes(auth.id))
  ) {
    return;
  }
  const noSystem = privs && !privs.sign && !privs.execute;
  yield put({
    type: GET_STREAMS_UNREAD_COUNTERS.REQUEST,
    payload: { noSystem, list: model.streams || [] },
  });
}

/**
 * Duplicate remove event for expanded actors as iframe
 */
function* removeActorIFrame({ id }) {
  const nodeModel = yield getActorByGraphId(id, 'nodes');
  // if actor is not expanded on graph, skip
  if (!nodeModel?.layerSettings?.expand) return;
  yield put({
    type: REMOVE_ACTOR.REQUEST,
    payload: { id, fromWs: true },
  });
}

/**
 * Update access of open actor
 */
function* updateActorViewAccess(model) {
  yield call(extendModelWithAvatars, model);
  yield call(updateActorView, {
    payload: { actorData: { id: model.id, access: model.access } },
  });
}

/**
 * Add new actor to list
 */
function* wsAddActor({ payload }) {
  const isHandle = yield call(isHandleWSPacket, payload);
  if (!isHandle) return;
  const isActive = yield isActiveTemplateList(payload.model);
  const isRecent = yield isUsersRecentActor(payload.model);
  if (!isActive && !isRecent) return;
  const actorsList = yield select((state) => state.actorsList);
  const { model, privs } = payload;
  const actorModel = structuredClone(model);
  const newList = actorsList.list.slice();
  const findEl = newList.find((i) => i.id === actorModel.id);
  if (findEl) return;
  yield call(extendModelWithAvatars, actorModel);
  actorModel.actorId = actorModel.id;
  actorModel.isNew = true;
  actorModel.unreadReactions = actorModel.unreadReactions || 0;
  actorModel.reactionsCount = actorModel.reactionsCount || 0;
  if (!AppUtils.isEmptyObject(privs)) actorModel.privs = privs;
  newList.unshift(actorModel);
  yield put({
    type: CREATE_ACTOR_LIST.SUCCESS,
    payload: { list: newList, activeActor: actorsList.activeActor },
  });
}

/**
 * Remove actor from list
 */
function* wsRemoveActor({ payload }) {
  const isHandle = yield call(isHandleWSPacket, payload);
  if (!isHandle) return;
  const { model, userId } = payload;
  yield call(removeActorIFrame, { id: model.id });
  const actorsList = yield select((state) => state.actorsList);
  const findEl = actorsList.list.find((i) => i.id === model.id);
  if (!findEl) return;
  const newList = actorsList.list.filter((i) => i.id !== model.id);
  const newActiveActor =
    model.id === actorsList.activeActor ? null : actorsList.activeActor;
  if (newList.length < actorsList.list.length) {
    yield put({
      type: REMOVE_ACTOR_LIST.SUCCESS,
      payload: {
        list: newList,
        activeActor: newActiveActor,
      },
    });
  }
  const actorView = yield select((state) => state.actorView);
  const auth = yield select((state) => state.auth);
  if (actorView.id === model.id && auth.id !== userId) {
    const mergeActor = { ...actorView, status: 'removed' };
    yield put({
      type: UPDATE_ACTOR_VIEW.SUCCESS,
      payload: mergeActor,
    });
  }
  const graphLayers = yield select((state) => state.graphLayers);
  const findLayer = graphLayers.list.find((l) => l.id !== model.id);
  if (findLayer) {
    yield put({
      type: CLOSE_LAYER.REQUEST,
      payload: { layerId: model.id },
    });
  }
}

/**
 * Update actor in list
 */
function* wsUpdateActor({ payload }) {
  const isHandle = yield call(isHandleWSPacket, payload);
  if (!isHandle) return;
  const isActive = yield isActiveTemplateList(payload.model);
  const { model, privs } = payload;
  if (privs) model.privs = privs;
  yield call(extendModelWithAvatars, model);
  if (isActive) {
    const actorsList = yield select((state) => state.actorsList);
    const actor = actorsList.list.find((i) => i.id === model.id);
    model.isNew = actor ? actor.isNew : model.isNew;
    const newList = AppUtils.replaceItemArrayObject(actorsList.list, 'id', {
      ...actor,
      ...model,
    });
    yield put({
      type: actor ? UPDATE_ACTOR_LIST.SUCCESS : CREATE_ACTOR_LIST.SUCCESS,
      payload: { list: newList, activeActor: actorsList.activeActor },
    });
  }
  const actorView = yield select((state) => state.actorView);
  if (actorView.id === model.id) {
    const invites = (actorView.access || []).filter((i) => !!i.isInvite);
    const access = model.access.concat(invites);
    const mergeActor = { ...actorView, ...model, access };
    yield put({
      type: UPDATE_ACTOR_VIEW.SUCCESS,
      payload: mergeActor,
    });
  }
}

/**
 * Add access to actor
 */
function* wsCreateActorAccess({ payload }) {
  const isHandle = yield call(isHandleWSPacket, payload);
  if (!isHandle) return;
  if (payload.objType !== 'actor') return;
  yield wsAddActor({ payload });
  yield callStreamCounters({ payload });
  yield updateActorViewAccess(payload.model);
}

/**
 * Update access to actor
 */
function* wsUpdateActorAccess({ payload }) {
  const isHandle = yield call(isHandleWSPacket, payload);
  if (!isHandle) return;
  if (payload.objType !== 'actor') return;
  yield wsUpdateActor({ payload });
  yield callStreamCounters({ payload });
}

/**
 * Check if user has access to object (personally or in group)
 */
export function* checkUserAccess(access) {
  const auth = yield select((state) => state.auth);
  const activeWorkspace = yield select((state) => state.settings.activeAccId);
  const myGroups = auth.workspaces[activeWorkspace].groups;
  const userAccess = access.find((i) => i.userId === auth.id);
  const groupAccess = userAccess
    ? true
    : myGroups.some((groupEl) =>
        access.some(({ userId }) => groupEl.id === userId)
      );
  return userAccess || groupAccess;
}

/**
 * Removed access to actor
 */
function* wsRemoveActorAccess({ payload }) {
  const { objType, model } = payload;
  if (objType !== 'actor') return;
  const access = model.access.concat(payload.model.defaultAccess);
  const hasAccess = yield call(checkUserAccess, access);
  if (hasAccess) {
    yield wsUpdateActor({ payload });
  } else {
    yield wsRemoveActor({ payload });
    // if another actor selected or actor viewed on graph, no info modal needed
    const actorView = yield select((state) => state.actorView);
    if (payload.model.id !== actorView.id) return;
    const graphLayers = yield select((state) => state.graphLayers);
    if (graphLayers.active) return;
    yield put({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'accessRules',
          text: 'removedAccess',
        },
        callback: () => {
          history.push('/');
        },
      },
    });
  }
}

/**
 * Search for event and stream in edge model
 */
function* findStreamInEdge({ model }) {
  const streamsFormId = yield select((state) => state.systemForms.streams.id);
  if (model.sourceActor.formId === streamsFormId) {
    return {
      streamId: model.source,
      event: model.targetActor,
    };
  }
  if (model.targetActor.formId === streamsFormId) {
    return {
      streamId: model.target,
      event: model.sourceActor,
    };
  }
  return {};
}

/**
 * Add or remove event in linked stream
 */
function* wsUpdateStreamEdge({ payload, action }) {
  const { model } = payload;
  const { streamId, event } = yield findStreamInEdge({ model });
  if (!streamId) return;
  const newModel = { ...payload, model: { ...event, streams: [streamId] } };
  yield callStreamCounters({ payload: newModel });
  const activeStreamId = yield select((state) => state.streams.active);
  if (streamId !== activeStreamId) return;
  yield action({ payload: newModel });
}

/**
 * Edge created
 */
function* wsCreateEdge({ payload }) {
  yield wsUpdateStreamEdge({ payload, action: wsAddActor });
}

/**
 * Edge removed
 */
function* wsRemoveEdge({ payload }) {
  const { model } = payload;
  yield removeEdge({
    payload: { id: model.id, withReq: false, manageLayer: false },
  });
  yield wsUpdateStreamEdge({ payload, action: wsRemoveActor });
}

/**
 * Update watcher
 */
export function* wsBalancesWatcherSaga() {
  const requestChannel = yield actionChannel(
    WS_UPDATE_BALANCES,
    buffers.sliding(1)
  );
  // prettier-ignore
  while (true) { // NOSONAR
    const action = yield take(requestChannel);
    yield call(wsUpdateBalances, action);
    yield delay(SUBSCRIPTION_INTERVAL);
  }
}

function* graphRealtime() {
  yield takeEvery(SUBSCRIBE_GRAPH.REQUEST, subscribeGraph);
  yield takeEvery(SUBSCRIBE_BALANCES.REQUEST, subscribeBalances);
  yield takeEvery(UNSUBSCRIBE_ALL_GRAPH.REQUEST, unsubscribeAllGraph);
  yield takeEvery(GRAPH_REALTIME_PROLONGATION, runSubProlongation);
  yield takeEvery(WS_DELETE_LAYER, layerDeleted);
  yield takeEvery(WS_UPDATE_ACTOR_ON_LAYER, updateActorOnLayer);
  yield takeEvery(WS_CREATE_ACTOR, wsAddActor);
  yield takeEvery(WS_DELETE_ACTOR, wsRemoveActor);
  yield takeEvery(WS_UPDATE_ACTOR, wsUpdateActor);
  yield takeEvery(WS_CREATE_ACCESS, wsCreateActorAccess);
  yield takeEvery(WS_UPDATE_ACCESS, wsUpdateActorAccess);
  yield takeEvery(WS_DELETE_ACCESS, wsRemoveActorAccess);
  yield takeEvery(WS_GRAPH_SUBSCRIBERS, wsGraphSubscribers);
  yield takeEvery(WS_CREATE_EDGE, wsCreateEdge);
  yield takeEvery(WS_DELETE_EDGE, wsRemoveEdge);
  yield debounce(1000, WS_LAYER_CHANGED, layerChanged);
  yield wsBalancesChanged();
}

export default graphRealtime;
