import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import cn from 'classnames';
import { useDispatchEffect } from 'hooks';
import mes from 'globalIntl';
import { PreJoin, LiveKitRoom } from '@livekit/components-react';
import '@livekit/components-styles';
import { cr, Label, Stack, Button } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import {
  CREATE_MEETING,
  END_MEETING,
  JOIN_MEETING,
} from '@control-front-end/common/constants/meeting';
import RecordingIndicator from './RecordingIndicator';
import MeetingConference from './MeetingConference';
import SettingsMenu from './SettingsMenu';
import scss from './MeetingRoom.scss';

/**
 * Handles the creation and interaction within a virtual meeting room, including pre-join configurations,
 * error handling, and connection to the LiveKit server.
 */
function MeetingRoom({
  actorId,
  parentActorId,
  participants,
  fullScreen,
  controlsVariation,
  onDisconnect,
}) {
  const dispatch = useDispatch();
  const serverUrl = useSelector((state) => state.config.livekitUrl);
  const auth = useSelector((state) => state.auth);
  const type = actorId ? JOIN_MEETING.REQUEST : CREATE_MEETING.REQUEST;
  const [preJoinChoices, setPreJoinChoices] = useState();
  const [isRecording, setRecording] = useState(false);

  /**
   * Memoized default configuration object for pre-join settings.
   */
  const preJoinDefaults = useMemo(() => {
    return {
      username: auth.nick,
      videoEnabled: true,
      audioEnabled: true,
    };
  }, []);

  /**
   * A variable representing a unique identifier or authorization credential.
   */
  const { token } =
    useDispatchEffect(
      {
        skip: !preJoinChoices,
        type,
        payload: { actorId, parentActorId, participants },
      },
      [preJoinChoices, actorId, parentActorId, participants]
    ) || {};

  /**
   * A function to handle errors by logging the error message in meeting room to the console.
   */
  const handleError = (e) => {
    console.error(e); // eslint-disable-line
  };

  /**
   * A function that handles the disconnection logic during a meeting.
   */
  const handleDisconnect = () => {
    if (fullScreen) dispatch({ type: END_MEETING.SUCCESS });
    if (onDisconnect) onDisconnect();
    AppUtils.stopSound();
  };

  /**
   * A React functional component that acts as a fallback UI to display when an error is encountered.
   */
  const errorFallback = ({ error }) => {
    handleError(error.message);
    return (
      <div>
        <Label value={mes.internalError} />
      </div>
    );
  };

  return (
    <Stack.V
      fullWidth
      fullHeight
      alignItems="center"
      justifyContent="center"
      className={cn(scss.livekit, { fullScreen })}
    >
      <ErrorBoundary FallbackComponent={errorFallback}>
        {cr(
          [
            token,
            <LiveKitRoom
              connect={true}
              video={preJoinChoices?.videoEnabled}
              audio={preJoinChoices?.audioEnabled}
              options={{
                adaptiveStream: true,
                dynacast: true,
                publishDefaults: {
                  videoCodec: 'vp9',
                  red: true,
                  dtx: true,
                  simulcast: false,
                },
                audioCaptureDefaults: {
                  autoGainControl: true,
                  echoCancellation: true,
                  noiseSuppression: true,
                },
              }}
              token={token}
              serverUrl={serverUrl}
              onError={handleError}
              onDisconnected={handleDisconnect}
            >
              <MeetingConference
                actorId={actorId}
                isRecording={isRecording}
                setRecording={setRecording}
                controlsVariation={controlsVariation}
                SettingsComponent={SettingsMenu}
              />
              <RecordingIndicator isRecording={isRecording} />
            </LiveKitRoom>,
          ],
          [
            !preJoinChoices,
            <>
              <PreJoin
                defaults={preJoinDefaults}
                onSubmit={setPreJoinChoices}
                onError={handleError}
              />
              {cr([
                fullScreen,
                <Button
                  className={scss.close}
                  type={Button.TYPE.quaternary}
                  icon="close"
                  rounded
                  onClick={handleDisconnect}
                />,
              ])}
            </>,
          ]
        )}
      </ErrorBoundary>
    </Stack.V>
  );
}

MeetingRoom.propTypes = {
  actorId: PropTypes.string,
  fullScreen: PropTypes.bool,
  parentActorId: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.number),
  controlsVariation: PropTypes.oneOf(['minimal', 'verbose']),
  onDisconnect: PropTypes.func,
};

export default MeetingRoom;
