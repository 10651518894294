import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Label } from 'mw-style-react';
import cn from 'classnames';

import ResizableCells from '@control-front-end/common/components/ResizableCells';
import { EXP_NODE } from '@control-front-end/common/constants/graphActors';
import useExpandActiveCell from '@control-front-end/app/src/routes/ActorsGraph/routers/Graph/hooks/useExpandActiveCell';
import { toCellCoord } from '@control-front-end/utils/modules/utilsCellCoords';
import { useGetGraphCoord } from 'hooks';

import { GRAPH_CELL_SIZE } from 'constants';

import scss from './ActiveCell.scss';

const BLINK_PAUSE_TIME_MS = 1000;

function ActiveCell({
  cy,
  focusPosition,
  isFocusCellOccupied,
  handleSaveActorLayerSettings,
}) {
  const [size, setSize] = useState({
    width: GRAPH_CELL_SIZE,
    height: GRAPH_CELL_SIZE,
  });
  const [activeCellPosition, setActiveCellPosition] = useState({ x: 0, y: 0 });
  const [resizableKey, setResizableKey] = useState(0);
  const [isResizableEnabled, setResizableEnabled] = useState(true);
  const blinkActiveCellSettings = useSelector(
    (state) => state.settings?.blinkActiveCell
  );
  const [isBlinking, setBlinking] = useState(true);
  const { expandActiveCell } = useExpandActiveCell({
    cy,
    handleSaveActorLayerSettings,
  });

  const containerResizableBoxRef = useRef(null);
  const containerRefActiveCell = useRef(null);
  const prevOffset = useRef(EXP_NODE.offset.none);
  const pauseTimeout = useRef(null);

  const pauseBlinking = () => {
    if (!blinkActiveCellSettings) return;
    setBlinking(false);
    if (pauseTimeout.current) clearTimeout(pauseTimeout.current);

    pauseTimeout.current = setTimeout(() => {
      setBlinking(true);
    }, BLINK_PAUSE_TIME_MS);
  };

  useEffect(() => {
    if (focusPosition) {
      setActiveCellPosition({ x: focusPosition.x, y: focusPosition.y });
      pauseBlinking();
    }
  }, [focusPosition]);

  const updateContainerTransform = useCallback(
    ({ pan, zoom }) => {
      if (!pan || !zoom) return;
      const transformStyle = `translate(${pan.x}px, ${pan.y}px) scale(${zoom})`;

      [
        containerResizableBoxRef.current,
        containerRefActiveCell.current,
      ].forEach((ref) => {
        if (ref) {
          ref.style.transform = transformStyle;
        }
      });
    },
    [containerRefActiveCell.current, containerResizableBoxRef.current]
  );

  useGetGraphCoord(cy, updateContainerTransform);

  useEffect(() => {
    const handleHoverStart = () => setResizableEnabled(false);
    const handleHoverEnd = () => setResizableEnabled(true);

    cy.on('mouseover', 'node', handleHoverStart);
    cy.on('mouseout', 'node', handleHoverEnd);
    return () => {
      cy.off('mouseover', 'node', handleHoverStart);
      cy.off('mouseout', 'node', handleHoverEnd);
    };
  }, [cy]);

  const resetSizeAndPosition = () => {
    // Incrementing the resizableKey forces React to recreate the ResizableCells component for reset value.
    setResizableKey((prevKey) => prevKey + 1);
    setSize({ width: GRAPH_CELL_SIZE, height: GRAPH_CELL_SIZE });
    setActiveCellPosition({ x: focusPosition.x, y: focusPosition.y });
    prevOffset.current = EXP_NODE.offset.none;
  };
  const toggleCyInteractions = (enabled) => {
    cy.panningEnabled(enabled);
    cy.boxSelectionEnabled(enabled);
    cy.zoomingEnabled(enabled);
    setBlinking(enabled);
  };

  const handleResizeStop = (e, { size: newSize, offset: newOffset }) => {
    toggleCyInteractions(true);
    resetSizeAndPosition();
    expandActiveCell({ position: focusPosition, newSize, newOffset });
  };

  if (!cy) return null;

  const handleOffsetChange = (offset) => {
    const deltaPosition = {
      x: offset.left - prevOffset.current.left,
      y: offset.top - prevOffset.current.top,
    };
    setActiveCellPosition((position) => ({
      x: position.x - deltaPosition.x,
      y: position.y - deltaPosition.y,
    }));

    prevOffset.current = offset;
  };

  const getActiveCellStyle = () => ({
    transform: `translate(${activeCellPosition.x - GRAPH_CELL_SIZE / 2}px, ${
      activeCellPosition.y - GRAPH_CELL_SIZE / 2
    }px)`,
    width: `${size.width || GRAPH_CELL_SIZE}px`,
    height: `${size.height || GRAPH_CELL_SIZE}px`,
    backgroundSize: `${GRAPH_CELL_SIZE}px ${GRAPH_CELL_SIZE}px`,
  });

  const getContainerStyles = (zIndex) => ({
    position: 'absolute',
    left: 0,
    top: 0,
    transformOrigin: 'left top',
    zIndex,
  });

  const labelValue = useMemo(() => {
    if (focusPosition) {
      const cellCoord = toCellCoord(focusPosition);
      return `${cellCoord.x}, ${cellCoord.y}`;
    }
    return '';
  }, [focusPosition]);

  return (
    <>
      <div ref={containerResizableBoxRef} style={getContainerStyles(6)}>
        {isResizableEnabled ? (
          <ResizableCells
            key={resizableKey}
            initialSize={{
              width: GRAPH_CELL_SIZE,
              height: GRAPH_CELL_SIZE,
            }}
            resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
            onResizeStart={() => {
              toggleCyInteractions(false);
            }}
            width={size.width}
            height={size.height}
            offset={EXP_NODE.offset.none}
            onSizeChange={setSize}
            onOffsetChange={handleOffsetChange}
            onResizeStop={handleResizeStop}
            minConstraints={[GRAPH_CELL_SIZE, GRAPH_CELL_SIZE]}
            position={focusPosition || { x: 0, y: 0 }}
          >
            <div />
          </ResizableCells>
        ) : null}
      </div>
      <div ref={containerRefActiveCell} style={getContainerStyles(-1)}>
        <div className={scss.activeCell} style={getActiveCellStyle()}>
          {!isFocusCellOccupied ? (
            <Label
              className={scss.cellCoordLabel}
              fontSize="small"
              value={labelValue}
              textAlign="end"
            />
          ) : null}
          <div
            className={cn(scss.activeCellbg, {
              [scss.blink]: isBlinking && blinkActiveCellSettings,
            })}
          />
        </div>
      </div>
    </>
  );
}

ActiveCell.propTypes = {
  focusPosition: PropTypes.object,
  cy: PropTypes.object,
  isFocusCellOccupied: PropTypes.bool,
  handleSaveActorLayerSettings: PropTypes.func,
};

export default ActiveCell;
