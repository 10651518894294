import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER_SETTINGS } from 'constants';
import { Utils } from 'mw-style-react';
import { sortBy } from 'lodash';

const RESERVED_4K_WIDTH_FOR_GRID = 3840;

const useReactGridLayout = () => {
  const dispatch = useDispatch();
  const accId = useSelector((state) => state.accounts.active);

  const getElementWidth = (element) =>
    Math.ceil(element.getBoundingClientRect().width);

  const calculateLastItemPosition = (lastLayoutItem) =>
    lastLayoutItem.x + lastLayoutItem.w;

  const getElementsSizes = (elements, idSelector) => {
    const sizes = {};

    Array.from(elements).forEach((element) => {
      sizes[idSelector(element)] = getElementWidth(element);
    });

    return sizes;
  };

  const sortItemsByPosition = (layout) => Utils.sort(layout, 'x');

  const handleAutoScroll = (dragEvent, container, threshold, speed) => {
    const clientX = dragEvent.touches
      ? dragEvent.touches[0].clientX
      : dragEvent.clientX;
    const rect = container.getBoundingClientRect();

    if (clientX - rect.left < threshold) {
      container.scrollLeft -= speed;
    } else if (rect.right - clientX < threshold) {
      container.scrollLeft += speed;
    }
  };

  const calculateGridWidthFromContentItems = (
    contentItemsCount,
    maxItemWidth
  ) => Math.max(RESERVED_4K_WIDTH_FOR_GRID, contentItemsCount * maxItemWidth);

  const calculateGridWidthFromColumns = (columnsCount, itemWidth, marginX) =>
    columnsCount * itemWidth + (columnsCount - 1) * marginX;

  const adjustItemsPositions = (layout, startIndex = 1) => {
    for (let i = startIndex; i < layout.length; i += 1) {
      layout[i] = {
        ...layout[i],
        x: calculateLastItemPosition(layout[i - 1]),
      };
    }
  };

  const createLayoutItem = ({
    id,
    x = 0,
    y = 0,
    width = 1,
    height = 1,
    isStatic = false,
  }) => ({
    i: id,
    x,
    y,
    w: width,
    h: height,
    static: isStatic,
  });

  const createLayout = ({
    contentItems,
    isFixedLayoutItemWidth = false,
    order = {},
    getWidth = () => 1,
    getStaticStatus = () => false,
  }) => {
    const layout = (
      Object.keys(order).length
        ? sortBy(contentItems, (item) => order[item.id])
        : contentItems
    ).map((item, index) =>
      createLayoutItem({
        id: item.id,
        x: isFixedLayoutItemWidth ? index : 0,
        width: getWidth(item),
        isStatic: getStaticStatus(item),
      })
    );

    if (!isFixedLayoutItemWidth) adjustItemsPositions(layout, 1);

    return layout;
  };

  const createOrderFromLayout = (layout, useXAsIndex) =>
    layout.reduce((order, item, index) => {
      order[item.i] = useXAsIndex ? item.x : index;
      return order;
    }, {});

  const updateOrderSettings = (
    newOrder,
    orderName,
    prevOrder = {},
    orderKey = null
  ) => {
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: {
        accId,
        [orderName]: orderKey
          ? {
              ...prevOrder,
              [orderKey]: newOrder,
            }
          : newOrder,
      },
    });
  };

  return {
    getElementsSizes,
    getElementWidth,
    updateOrderSettings,
    createOrderFromLayout,
    calculateLastItemPosition,
    sortItemsByPosition,
    calculateGridWidthFromContentItems,
    calculateGridWidthFromColumns,
    createLayout,
    createLayoutItem,
    adjustItemsPositions,
    handleAutoScroll,
  };
};

export default useReactGridLayout;
