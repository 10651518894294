import React, { useEffect, useState } from 'react';
import { filter, some, find } from 'lodash';
import PropTypes from 'prop-types';
import { BulkActionsHeader } from 'components';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Label,
  Avatar,
  DateUtils,
  Icon,
  Menu,
  MenuItem,
  Divider,
  FileUtils,
  Clipboard,
  Popover,
  Space,
  TextField,
  cr,
  Stack,
} from 'mw-style-react';
import cn from 'classnames';
import { DATE_FORMAT, DATE_FORMAT_6 } from 'constants';
import { useIntl, useModal, useNotifications } from 'hooks';
import tableScss from '@control-front-end/common/styles/table.scss';
import AppUtils from '@control-front-end/utils/utils';
import useBulkActions from '@control-front-end/app/src/routes/ActorsGraph/hooks/useBulkActions';
import ItemCheckBox from '@control-front-end/app/src/components/BulkActionsHeader/ItemCheckBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  DOWNLOAD_SELECTED_ACTOR_ATTACHES,
  RENAME_ACTOR_ATTACH,
} from '@control-front-end/common/constants/graphActors';
import mes from './intl';
import scss from './AttachList.scss';

/**
 * List of attachments
 */
function AttachList(props) {
  const { list = [], canEdit = false, onDelete } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const showNotification = useNotifications();
  const { open: openFilePreview } = useModal('FilePreview', {});
  const columnSize = ['50%', 104, '30%', 48, 32];

  const actor = useSelector((state) => state.actorView) || {};
  const { title, id: actorId } = actor;
  const accId = useSelector((state) => state.accounts.active);

  const { selectedItems, setSelectedItems, handleSelection } = useBulkActions({
    objType: 'attachment',
    list,
  });

  const [editMode, setEditMode] = useState(null);

  const copyLink = (file) => {
    const link = `${document.location.origin}${file.filePath}?preview=true`;
    Clipboard.copy(link);
    showNotification.success(t(mes.linkCopied));
  };

  // Download attached file
  const downloadFile = (file) => {
    const filePath =
      file.filePath || AppUtils.makeAppUrl(`/download/${file.fileName}`);
    FileUtils.download(filePath, file.title);
  };

  const handleOnCancelRename = () => {
    setEditMode(null);
  };

  const handleEditMode = (file) => {
    setEditMode({ value: file.title, fileId: file.id });
  };

  const onSubmitRename = (newFileName, fileId) => {
    dispatch({
      type: RENAME_ACTOR_ATTACH.REQUEST,
      payload: {
        fileId,
        newFileName,
        actorId,
      },
    });
  };

  const handleOnSubmitRename = () => {
    const trimmedValue = editMode.value.trim();
    if (!trimmedValue.length) return;
    const item = list?.find((item) => item.id === editMode?.fileId);
    if (item?.title !== trimmedValue) {
      onSubmitRename(trimmedValue, editMode.fileId);
    }
    handleOnCancelRename();
  };

  const handleRenameInputKeyUp = (e) => {
    if (['Enter'].includes(e.key)) {
      handleOnSubmitRename();
    } else if (['Escape'].includes(e.key)) {
      handleOnCancelRename();
    }
  };

  const handleOnNameChanged = ({ value }) => {
    setEditMode({ ...editMode, value });
  };

  const handleMenuClick = ({ file, value, onClose }) => {
    switch (value) {
      case 'copy_link':
        copyLink(file);
        break;
      case 'download':
        downloadFile(file);
        break;
      case 'delete':
        onDelete(file);
        break;
      case 'rename':
        handleEditMode(file);
        break;
      default:
        break;
    }
    onClose();
  };

  // Render menu for attached file
  const renderMenu = ({ file, onClose }) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          className={tableScss.itemMenu}
          size="small"
          width={200}
          unspaced
          onClick={({ value }) => handleMenuClick({ file, value, onClose })}
          onClose={onClose}
        >
          <MenuItem
            leftIcon="link"
            size="small"
            label={t(mes.copyLink)}
            value="copy_link"
          />
          <MenuItem
            leftIcon="download"
            size="small"
            label={t(mes.download)}
            value="download"
          />
          <MenuItem
            leftIcon="edit"
            size="small"
            label={t(mes.rename)}
            value="rename"
          />
          <Divider />
          <MenuItem
            leftIcon="trash"
            size="small"
            label={t(mes.remove)}
            value="delete"
            visibility={canEdit && onDelete ? 'visible' : 'hidden'}
          />
        </Menu>
      </div>
    );
  };

  const renderFilePreview = (file) => {
    if (file.type.indexOf('image') !== -1) {
      const filePath =
        file.filePath || AppUtils.makeAppUrl(`/download/${file.fileName}`);
      return (
        <div className={scss.imgWrap}>
          <img id={file.id} className={scss.previewImg} src={filePath} alt="" />
        </div>
      );
    }

    return (
      <div className={scss.fileWrap}>
        <Icon type="attach" />
      </div>
    );
  };

  /**
   * Correct selected items in case of removing some of them (WS or context menu)
   */
  useEffect(() => {
    if (!selectedItems.length || !list.length) return;
    setSelectedItems((selectedItems) =>
      filter(selectedItems, (i) => some(list, { id: i.id }))
    );
  }, [list.length]);

  const handleBulkSelect = (action) => {
    if (action === 'add') {
      const selectedForms = selectedItems.slice();
      selectedForms.push(...list);
      const uniqueItems = AppUtils.uniqueObjArray(selectedForms, 'id');
      setSelectedItems(uniqueItems);
    } else {
      const restSelectedForms = selectedItems.filter(
        (sItem) => !list.find((item) => sItem.id === item.id)
      );
      setSelectedItems(restSelectedForms);
    }
  };

  /**
   * Handle download selected attachments
   */
  const handleDownload = () => {
    if (selectedItems.length === 0) {
      return;
    }
    const filesData = selectedItems.map(({ title, fileName }) => ({
      title,
      fileName,
    }));
    dispatch({
      type: DOWNLOAD_SELECTED_ACTOR_ATTACHES.REQUEST,
      payload: {
        accId,
        filesData,
        attachTitle: `${title}_${DateUtils.toDate(
          DateUtils.dateToUnixtime(new Date()),
          DATE_FORMAT_6
        )}`,
      },
      callback: () => {
        showNotification.success(t(mes.selectedAttachmentsDownloadInQueue));
      },
    });
  };

  const renderHeader = () => {
    return (
      <TableRow
        className={cn(tableScss.row, scss.attachHeaderRow)}
        rowSize="small"
      >
        <TableCell className={tableScss.cell}>
          <BulkActionsHeader
            objType="attachment"
            actions={['download']}
            list={list}
            total={list.length}
            selectedIds={selectedItems.map((i) => i.id)}
            handleBulkSelect={handleBulkSelect}
            handleDownloadButton={handleDownload}
          />
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label value={t(mes.attachFileSize)} />
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label value={t(mes.date)} />
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label value={t(mes.owner)} />
        </TableCell>
        <TableCell className={scss.menu} />
      </TableRow>
    );
  };

  const renderRows = () => {
    return list.map((file) => (
      <TableRow
        key={file.id}
        className={cn(tableScss.row, tableScss.clickable, scss.attachRow)}
        rowSize="small"
        onClick={() => openFilePreview({ ...file, files: list })}
      >
        <TableCell className={tableScss.cell}>
          <ItemCheckBox
            itemId={file.id}
            active={!!find(selectedItems, { id: file.id })}
            onClick={handleSelection}
          />
          <Space left right size="small">
            {renderFilePreview(file)}
          </Space>
          {cr(
            [
              editMode?.fileId === file.id,
              <Stack.H fullWidth onClick={(e) => e.stopPropagation()}>
                <TextField
                  autoFocus
                  bordered
                  unspaced
                  className={scss.renameTextField}
                  multilineType="text"
                  autoSelect="mount"
                  error={editMode?.value.length === 0}
                  value={editMode?.value}
                  onChange={handleOnNameChanged}
                  onBlur={handleOnSubmitRename}
                  onKeyUp={handleRenameInputKeyUp}
                />
              </Stack.H>,
            ],
            [true, <Label value={file.title} overflow="cut" />]
          )}
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label value={FileUtils.biteToSize(file.size)} />
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label value={DateUtils.toDate(file.createdAt, DATE_FORMAT)} />
        </TableCell>
        <TableCell className={tableScss.cell}>
          {file.userAvatar ? (
            <Avatar size="small" src={file.userAvatar} label={file.name} />
          ) : null}
        </TableCell>
        <TableCell className={tableScss.menu}>
          <div onClick={(e) => e.stopPropagation()}>
            <Popover
              topLevel
              anchors={{
                binding: Popover.ANCHOR.right_bottom,
                content: Popover.ANCHOR.right_top,
              }}
              content={({ onClose }) => renderMenu({ file, onClose })}
            >
              <Icon className={tableScss.menuIcon} type="more" />
            </Popover>
          </div>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Table
      paddingSize="small"
      className={cn(scss.attachTable, tableScss.table)}
      columnSize={columnSize}
    >
      <TableHead fixed={true}>{renderHeader()}</TableHead>
      <TableBody>{renderRows()}</TableBody>
    </Table>
  );
}

AttachList.propTypes = {
  list: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default AttachList;
