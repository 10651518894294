import { useDispatch } from 'react-redux';
import { NOTIFY_LEVEL, SHOW_NOTIFY, PM_APP_NAME } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { mapKeys, mapValues } from 'lodash';

/**
 * Hook for notifications
 */
function useNotifications() {
  const dispatch = useDispatch();

  const showNotification = (type, message) => {
    const payload = {
      id: AppUtils.createRid(),
      type: NOTIFY_LEVEL[type.toUpperCase()],
      label: message,
    };

    if (window.frameElement) {
      window.top.postMessage(
        {
          type: 'SHOW_NOTIFY_TOP_WINDOW',
          appName: PM_APP_NAME,
          data: { payload },
        },
        window.parent.origin
      ); // NOSONAR
    } else {
      dispatch({
        type: SHOW_NOTIFY.REQUEST,
        payload,
      });
    }
  };

  return {
    showNotification, // deprecated, use showNotification with level-key
    ...mapValues(
      mapKeys(NOTIFY_LEVEL, (value, key) => key.toLowerCase()),
      (level) => {
        return (message) => showNotification(level, message);
      }
    ),
  };
}

export default useNotifications;
