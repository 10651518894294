import { cr, DateUtils } from 'mw-style-react';
import mapValues from 'lodash/mapValues';

import {
  DATE_FORMAT,
  TIMEFRAME_NAME,
  MILLISECONDS_PER_SECOND,
  DATE_FORMAT_6,
  DATE_FORMAT_4,
  DATE_FORMAT_2,
} from 'constants';
import { DASHBOARD_DATA_INTERVAL } from '@control-front-end/common/constants/graphActors';

const SECONDS_PER_MINUTE = 60;
const MINUTE = SECONDS_PER_MINUTE * 1000;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;

const createTimestamp = (...dateParams) => new Date(...dateParams).getTime();
const toUnixSeconds = (timestamp) => Math.round(timestamp / 1000);
const createTimeRange = (fromTime, toTime) => ({
  from: toUnixSeconds(fromTime),
  to: toUnixSeconds(toTime),
});

const APP_CREATION_DATE = createTimestamp(2018, 8, 1, 0, 0, 0, 0);

const RANGE_BY_TIMEFRAME_NAME = {
  [TIMEFRAME_NAME.all]: () =>
    createTimeRange(
      APP_CREATION_DATE,
      DateUtils.endOf(createTimestamp(), 'day')
    ),
  [TIMEFRAME_NAME.lastHour]: () =>
    createTimeRange(createTimestamp() - HOUR, createTimestamp()),
  [TIMEFRAME_NAME.lineRealTime]: () =>
    createTimeRange(createTimestamp() - HOUR, createTimestamp()),
  [TIMEFRAME_NAME.last10Minutes]: () =>
    createTimeRange(createTimestamp() - 10 * MINUTE, createTimestamp()),
  [TIMEFRAME_NAME.lastMinute]: () =>
    createTimeRange(createTimestamp() - MINUTE, createTimestamp()),
  [TIMEFRAME_NAME.today]: () =>
    createTimeRange(
      DateUtils.startOf(createTimestamp(), 'day'),
      DateUtils.endOf(createTimestamp(), 'day')
    ),
  [TIMEFRAME_NAME.yesterday]: () =>
    createTimeRange(
      DateUtils.startOf(createTimestamp() - DAY, 'day'),
      DateUtils.endOf(createTimestamp() - DAY, 'day')
    ),
  [TIMEFRAME_NAME.lastWeek]: () =>
    createTimeRange(
      DateUtils.startOf(createTimestamp() - WEEK, 'day'),
      DateUtils.endOf(createTimestamp(), 'day')
    ),
  [TIMEFRAME_NAME.lastMonth]: () =>
    createTimeRange(
      DateUtils.startOf(createTimestamp() - MONTH, 'day'),
      DateUtils.endOf(createTimestamp(), 'day')
    ),
  [TIMEFRAME_NAME.previousMonth]: () => {
    const now = new Date();
    const previousMonthStart = new Date(
      now.getFullYear(),
      now.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );

    return createTimeRange(
      previousMonthStart,
      DateUtils.endOf(previousMonthStart, 'month')
    );
  },
  [TIMEFRAME_NAME.allTime]: () => ({}),
  [TIMEFRAME_NAME.realTime]: () => ({}),
  [TIMEFRAME_NAME.now]: () =>
    createTimeRange(createTimestamp(), createTimestamp()),
};

const TRANSFORM_TYPE = {
  secToMs: 'secToMs',
  msToSec: 'msToSec',
};

const USER_TIMEZONE_OFFSET = new Date().getTimezoneOffset();

export default {
  TRANSFORM_TYPE,

  toUnixSeconds,

  transform: {
    [TRANSFORM_TYPE.secToMs]: (sec) => sec * MILLISECONDS_PER_SECOND,
    [TRANSFORM_TYPE.msToSec]: (ms) => ms / MILLISECONDS_PER_SECOND,
  },

  transformRange(range) {
    return {
      [TRANSFORM_TYPE.secToMs]: mapValues(range, (item) =>
        this.transform[TRANSFORM_TYPE.secToMs](item)
      ),
      [TRANSFORM_TYPE.msToSec]: mapValues(range, (item) =>
        this.transform[TRANSFORM_TYPE.msToSec](item)
      ),
    };
  },

  // Получить корректный диапазон для таймзоны
  getRangeForTimeZone(range) {
    const timeZone = new Date().getTimezoneOffset();
    const timeZoneOffset = timeZone * MINUTE;
    return {
      from: Math.round((range.from - timeZoneOffset) / 1000),
      to: Math.round((range.to - timeZoneOffset) / 1000),
    };
  },

  /** Provides from and to time range based on the timeframe name */
  getRangeDate(timeframeName, convertToMs = false) {
    let range = timeframeName;
    if (typeof timeframeName === 'string') {
      range =
        RANGE_BY_TIMEFRAME_NAME[
          TIMEFRAME_NAME[timeframeName] || TIMEFRAME_NAME.now
        ]();
    }
    return convertToMs
      ? this.transformRange(range)[TRANSFORM_TYPE.secToMs]
      : range;
  },

  /** Provides timeframe name based on the time range (from and to) */
  getTimeFrameName(range) {
    return Object.keys(TIMEFRAME_NAME).find((timeframeName) => {
      const { from, to } = RANGE_BY_TIMEFRAME_NAME[timeframeName]();
      return from === range.from && to === range.to;
    });
  },

  // Default due date for events
  getDefaultDuedate() {
    return DateUtils.unixtime() + 5 * 24 * 60 * 60;
  },

  isValidDate(unix) {
    const date = new Date(unix * 1000);
    return date instanceof Date && !isNaN(date);
  },

  // Формат даты
  getTextDate(startDateUnix, endDateUnix) {
    if (!this.isValidDate(startDateUnix) || !this.isValidDate(endDateUnix))
      return 'Invalid Date';
    const formattedEndDate = DateUtils.toDate(endDateUnix, DATE_FORMAT);
    if (startDateUnix !== endDateUnix) {
      const formattedStartDate = DateUtils.toDate(startDateUnix, DATE_FORMAT);
      return `${formattedStartDate} - ${formattedEndDate}`;
    }

    return formattedEndDate;
  },

  relativeDate(unixtime, [t1, t2, t3, t4, t5, t6, t7]) {
    const now = new Date().getTime() / 1000;
    const delta = Math.round(now - unixtime);
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    if (delta < 30) {
      return t1;
    }
    if (delta < minute) {
      return `${delta} ${t2}`;
    }
    if (delta < 2 * minute) {
      return t3;
    }
    if (delta < hour) {
      return `${Math.floor(delta / minute)} ${t4}`;
    }
    if (Math.floor(delta / hour) === 1) {
      return t5;
    }
    if (delta < day) {
      return `${Math.floor(delta / hour)} ${t6}`;
    }
    if (delta < day * 2) {
      return t7;
    }
    return DateUtils.toDate(unixtime, DATE_FORMAT);
  },

  getTimeAgo(unix) {
    const todayDate = DateUtils.unixtime();
    const unixDiff = todayDate - unix;
    const minute = 60;
    const hour = 60 * 60;
    const day = 60 * 60 * 24;
    let timeAgo;
    if (unixDiff < 10) {
      return 'now';
    }
    if (unixDiff < minute) {
      timeAgo = `${unixDiff}s`;
    } else if (unixDiff > day) {
      return null;
    } else if (unixDiff < hour) {
      const minutesAgo = Math.floor(unixDiff / minute);
      timeAgo = `${minutesAgo}m`;
    } else {
      const hoursAgo = Math.floor(unixDiff / hour);
      timeAgo = `${hoursAgo}h`;
    }
    return timeAgo;
  },

  // Get interval for dashboard metrics according to range
  getChartRangeIntervals({ from, to }) {
    if (!from || !to) return { interval: null };

    const timeIntervals = [
      { maxDiff: HOUR, step: MINUTE, interval: DASHBOARD_DATA_INTERVAL.MINUTE },
      { maxDiff: DAY, step: HOUR, interval: DASHBOARD_DATA_INTERVAL.HOUR },
      {
        maxDiff: MONTH + DAY + HOUR, // 31 day + 1 hour for the summertime
        step: DAY,
        interval: DASHBOARD_DATA_INTERVAL.DAY,
      },
      { step: MONTH, interval: DASHBOARD_DATA_INTERVAL.MONTH },
    ];
    // Month by default
    let selectedInterval = timeIntervals[timeIntervals.length - 1];
    let tick = from;
    for (const interval of timeIntervals) {
      if (to - from <= interval.maxDiff) {
        selectedInterval = interval;
        break;
      }
    }

    const steps = [];
    if (selectedInterval.interval === DASHBOARD_DATA_INTERVAL.MONTH) {
      const d = DateUtils.startOf(from, 'month');
      tick = d.getTime();
      steps.push(tick);
      while (to - tick >= selectedInterval.step) {
        d.setMonth(d.getMonth() + 1);
        tick = d.getTime();
        steps.push(tick);
      }
    } else {
      steps.push(tick);
      while (to - tick >= selectedInterval.step) {
        tick += selectedInterval.step;
        steps.push(tick);
      }
    }
    return { interval: selectedInterval.interval, steps };
  },

  // Make view for actor data with class "calendar"
  makeCalendarItemValue(range, extra = {}) {
    const { startDate, endDate } = range || {};
    if (extra.static && startDate) {
      return DateUtils.toDate(this.applyOffsetToUTC(startDate), DATE_FORMAT_6);
    }
    const format = cr(
      [extra.format, extra.format],
      [extra.time, DATE_FORMAT_4],
      [true, DATE_FORMAT_2]
    );
    const arrDate = [];
    if (startDate) arrDate.push(DateUtils.toDate(startDate, format));
    if (endDate && startDate !== endDate) {
      arrDate.push(DateUtils.toDate(endDate, format));
    }
    return arrDate.join(' - ');
  },

  normalizeToUTC(unix) {
    return unix - USER_TIMEZONE_OFFSET * SECONDS_PER_MINUTE;
  },

  applyOffsetToUTC(unix) {
    return unix + USER_TIMEZONE_OFFSET * SECONDS_PER_MINUTE;
  },
};
