import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon, Label, Space, Stack, TextField } from 'mw-style-react';
import cn from 'classnames';
import scss from './ChartTitle.scss';

/**
 * Editable chart title
 */
function ChartTitle({ title, readOnly, onSubmit }) {
  const [newTitle, setNewTitle] = useState(title);
  const [editMode, toggleEditMode] = useState(false);

  const saveChartTitle = () => {
    if (newTitle.length && newTitle !== title) {
      onSubmit(newTitle);
    }
    toggleEditMode(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      saveChartTitle();
    } else if (e.key === 'Escape') {
      toggleEditMode(false);
    }
  };

  const renderTitleLabel = () => {
    return (
      <Space
        className={cn({ [scss.editable]: !readOnly })}
        fullHeight
        left
        right
        size={Space.SIZE.xxsmall}
      >
        <Stack.H
          fullWidth
          alignContent="center"
          onClick={(e) => {
            if (readOnly) return;
            e.stopPropagation();
            toggleEditMode(true);
          }}
        >
          <Label
            className={scss.label}
            fontSize={Label.SIZES.large}
            fontWeight={Label.WEIGHTS.semibold}
            overflow={Label.OVERFLOW.cut}
            value={title}
          />
          {!readOnly ? (
            <Icon className={scss.editIcon} type="edit" size="small" />
          ) : null}
        </Stack.H>
      </Space>
    );
  };

  const renderTitleEdit = () => {
    return (
      <Space
        className={scss.editField}
        fullHeight
        left
        right
        size={Space.SIZE.xxsmall}
      >
        <Stack.H fullWidth>
          <TextField
            className={scss.textInput}
            bordered={false}
            unspaced
            autoFocus
            autoSelect="mount"
            value={newTitle}
            onChange={({ value }) => setNewTitle(value)}
            onKeyDown={handleKeyPress}
            onBlur={saveChartTitle}
          />
        </Stack.H>
      </Space>
    );
  };
  return (
    <Stack.V className={scss.title} alignContent="center">
      {editMode ? renderTitleEdit() : renderTitleLabel()}
    </Stack.V>
  );
}

ChartTitle.propTypes = {
  title: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChartTitle;
