import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotifications, useIntl } from 'hooks';
import { cr, Stack } from 'mw-style-react';
import sccs from './MeetingRoom.scss';
import mes from './intl';

function RecordingIndicator({ isRecording }) {
  const t = useIntl();
  const [wasRecording, setWasRecording] = useState(false);
  const { showNotification } = useNotifications();

  useEffect(() => {
    if (isRecording !== wasRecording) {
      setWasRecording(isRecording);
      if (isRecording) {
        showNotification('success', t(mes.recordingStarted));
      }
    }
  }, [isRecording]);

  return cr([
    isRecording,
    <Stack.H className={sccs.indicator}>
      <div className={sccs.wrapper}>
        <div className={sccs.dot} />
        <div className={sccs.ring} />
      </div>
    </Stack.H>,
  ]);
}

RecordingIndicator.propTypes = {
  isRecording: PropTypes.bool,
};

export default RecordingIndicator;
