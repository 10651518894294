import React, { useRef, useEffect, useState } from 'react';
import { RoomEvent, Track } from 'livekit-client';
import {
  isWeb,
  isEqualTrackRef,
  isTrackReference,
} from '@livekit/components-core';
import {
  ControlBar,
  GridLayout,
  FocusLayout,
  CarouselLayout,
  ParticipantTile,
  RoomAudioRenderer,
  FocusLayoutContainer,
  ConnectionStateToast,
  LayoutContextProvider,
  useTracks,
  usePinnedTracks,
  useCreateLayoutContext,
  useParticipantAttribute,
} from '@livekit/components-react';
import PropTypes from 'prop-types';

/**
 * A functional component that renders a participant tile with user avatar and name.
 * It dynamically determines whether to show the participant tile with the camera or an avatar placeholder
 * depending on the participant's camera state.
 */
function Participant() {
  const ref = useRef();
  const userAvatar = useParticipantAttribute('userAvatar');
  const placeholderClass = '.lk-participant-placeholder';

  useEffect(() => {
    if (ref.current) {
      // a simple fix for changing the user avatar, because there is no native method in the sdk
      const img = ref.current.querySelector(placeholderClass);
      if (img) img.style.backgroundImage = `url(${userAvatar})`;
    }
  }, []);

  return <ParticipantTile ref={ref} />;
}

/**
 * MeetingConference component copy from @livekit/components-react
 */
function MeetingConference({
  actorId,
  isRecording,
  setRecording,
  controlsVariation = 'verbose',
  SettingsComponent,
}) {
  const lastAutoFocusedScreenShareTrack = useRef();
  const [widgetState, setWidgetState] = useState({
    showChat: false,
    unreadMessages: 0,
    showSettings: false,
  });
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false }
  );

  const layoutContext = useCreateLayoutContext();

  const screenShareTracks = tracks
    .filter(isTrackReference)
    .filter((track) => track.publication.source === Track.Source.ScreenShare);

  const focusTrack = usePinnedTracks(layoutContext)?.[0];
  const carouselTracks = tracks.filter(
    (track) => !isEqualTrackRef(track, focusTrack)
  );

  useEffect(() => {
    // If screen share tracks are published, and no pin is set explicitly, auto set the screen share.
    if (
      screenShareTracks.some((track) => track.publication.isSubscribed) &&
      lastAutoFocusedScreenShareTrack.current === null
    ) {
      layoutContext.pin.dispatch?.({
        msg: 'set_pin',
        trackReference: screenShareTracks[0],
      });
      lastAutoFocusedScreenShareTrack.current = screenShareTracks[0];
    } else if (
      lastAutoFocusedScreenShareTrack.current &&
      !screenShareTracks.some(
        (track) =>
          track.publication.trackSid ===
          lastAutoFocusedScreenShareTrack.current?.publication?.trackSid
      )
    ) {
      layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
      lastAutoFocusedScreenShareTrack.current = null;
    }
    if (focusTrack && !isTrackReference(focusTrack)) {
      const updatedFocusTrack = tracks.find(
        (tr) =>
          tr.participant.identity === focusTrack.participant.identity &&
          tr.source === focusTrack.source
      );
      if (
        updatedFocusTrack !== focusTrack &&
        isTrackReference(updatedFocusTrack)
      ) {
        layoutContext.pin.dispatch?.({
          msg: 'set_pin',
          trackReference: updatedFocusTrack,
        });
      }
    }
  }, [
    screenShareTracks
      .map(
        (ref) => `${ref.publication.trackSid}_${ref.publication.isSubscribed}`
      )
      .join(),
    focusTrack?.publication?.trackSid,
    tracks,
  ]);

  return (
    <div className="lk-video-conference">
      {isWeb() && (
        <LayoutContextProvider
          value={layoutContext}
          onWidgetChange={setWidgetState}
        >
          <div className="lk-video-conference-inner">
            {!focusTrack ? (
              <div className="lk-grid-layout-wrapper">
                <GridLayout tracks={tracks}>
                  <Participant />
                </GridLayout>
              </div>
            ) : (
              <div className="lk-focus-layout-wrapper">
                <FocusLayoutContainer>
                  <CarouselLayout tracks={carouselTracks}>
                    <Participant />
                  </CarouselLayout>
                  {focusTrack && <FocusLayout trackRef={focusTrack} />}
                </FocusLayoutContainer>
              </div>
            )}
            <ControlBar
              variation={controlsVariation}
              controls={{ chat: false, settings: !!SettingsComponent }}
            />
          </div>
          {SettingsComponent && (
            <div
              className="lk-settings-menu-modal"
              style={{ display: widgetState.showSettings ? 'block' : 'none' }}
            >
              <SettingsComponent
                actorId={actorId}
                isRecording={isRecording}
                setRecording={setRecording}
              />
            </div>
          )}
        </LayoutContextProvider>
      )}
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </div>
  );
}

MeetingConference.propTypes = {
  actorId: PropTypes.string,
  isRecording: PropTypes.bool,
  setRecording: PropTypes.func,
  SettingsComponent: PropTypes.node,
  controlsVariation: PropTypes.oneOf(['minimal', 'verbose']),
};

export default MeetingConference;
