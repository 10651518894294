import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl } from 'hooks';
import { Avatar, Button, Card, Label, Space, Stack } from 'mw-style-react';
import {
  ACCEPT_MEETING,
  REJECT_MEETING,
} from '@control-front-end/common/constants/meeting';
import mes from './intl';
import sccs from './MeetingCall.scss';

/**
 * Renders the MeetingCall component, which displays a call interface with options to accept or reject a meeting call.
 */
function MeetingCall({ user }) {
  const t = useIntl();
  const dispatch = useDispatch();

  const handleAnswer = () => {
    dispatch({ type: ACCEPT_MEETING.REQUEST });
  };

  const handleHangup = () => {
    dispatch({ type: REJECT_MEETING.REQUEST });
  };

  return (
    <div className={sccs.bg}>
      <Stack.H>
        <Card
          className={sccs.callScreen}
          withBorder={false}
          color="primary"
          borderRadius="xlarge"
        >
          <Space size={Space.SIZE.xlarge}>
            <Stack>
              <Stack alignItems="center">
                <Avatar
                  bgColor={Label.COLOR.white}
                  size="xxlarge"
                  src={user?.userAvatar}
                  label={user?.nick}
                />
              </Stack>
              <Stack alignItems="center">
                <Label
                  color={Label.COLOR.white}
                  fontSize="xlarge"
                  value={user?.nick}
                  overflow="break"
                />
              </Stack>
              <Stack alignItems="center">
                <Label color={Label.COLOR.white} value={t(mes.meetCalling)} />
              </Stack>
              <Stack horizontal fullWidth justifyContent="center">
                <Button
                  size={Button.SIZE.large}
                  className={sccs.btnCall}
                  rounded
                  icon="call"
                  onClick={handleAnswer}
                />
                <Button
                  type={Button.TYPE.error}
                  size={Button.SIZE.large}
                  rounded
                  icon="call_off"
                  onClick={handleHangup}
                />
              </Stack>
            </Stack>
          </Space>
        </Card>
      </Stack.H>
    </div>
  );
}

MeetingCall.propTypes = {
  user: PropTypes.shape({
    userAvatar: PropTypes.string,
    nick: PropTypes.string,
  }),
};

export default MeetingCall;
